import { BoxProps, chakra, Spinner } from '@chakra-ui/react';
import { forwardRef } from 'react';
import COLORS from 'ui/colors';
import FONTS from 'ui/fonts';
import { ICONS } from 'ui/icons';
import PX from 'ui/px';

type CustomButtonProps = BoxProps & {
    buttonColor?: {
        border?: string;
        fill?: string;
        text?: string;
        hover?: string;
    };
    isLoading?: boolean;
    disabled?: boolean;
    iconLeft?: keyof typeof ICONS;
    iconRight?: keyof typeof ICONS;
};

type FullButtonProps = BoxProps & {
    primary?: boolean;
};

export const Button = forwardRef<BoxProps, FullButtonProps>(
    ({ primary, ...props }, ref) => {
        return primary ? (
            <PrimaryButton ref={ref} {...props} />
        ) : (
            <SecondaryButton ref={ref} {...props} />
        );
    },
);

//Both Primary and Secondary share the BaseButton
export const BaseButton = forwardRef<BoxProps, CustomButtonProps>(
    ({ isLoading, buttonColor, iconRight, iconLeft, ...props }, ref) => {
        const ICONRIGHT = !!iconRight ? ICONS[iconRight] : undefined;
        const ICONLEFT = !!iconLeft ? ICONS[iconLeft] : undefined;

        return (
            <chakra.button
                role="button"
                paddingY={PX.SPACING.REM.XXS}
                paddingX={PX.SPACING.REM.L}
                borderRadius={PX.RADII.ROUNDED}
                border={`1px solid ${buttonColor?.border ?? COLORS.STROKES.HEAVY}`}
                opacity={props.disabled ? 0.5 : 1}
                fontWeight="bold"
                transition="all 0.3s"
                width="fit-content"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap={PX.SPACING.PX.S}
                tabIndex={props.disabled ? -1 : 0}
                cursor={props.disabled ? 'default' : 'pointer'}
                _hover={
                    props.disabled
                        ? undefined
                        : {
                              background: buttonColor?.hover,
                          }
                }
                _focus={
                    props.disabled
                        ? undefined
                        : {
                              background: buttonColor?.hover,
                          }
                }
                _active={
                    props.disabled
                        ? undefined
                        : {
                              transform: 'scale(0.97)',
                          }
                }
                background={buttonColor?.fill}
                color={buttonColor?.text}
                css={{ WebkitTapHighlightColor: 'transparent' }}
                ref={ref}
                {...props}
            >
                {ICONLEFT && (
                    <ICONLEFT
                        color={(buttonColor?.text ?? props.color) as string}
                        size={16}
                        style={{ zIndex: 1 }}
                    />
                )}

                {isLoading && <Spinner size="xs" />}

                <FONTS.P1
                    color={
                        buttonColor?.text ??
                        props.color ??
                        COLORS.UTIL.Gray[800]
                    }
                >
                    {props.children}
                </FONTS.P1>
                {ICONRIGHT && (
                    <ICONRIGHT
                        color={props.color as string}
                        size={16}
                        style={{ zIndex: 1 }}
                    />
                )}
            </chakra.button>
        );
    },
);

export const PrimaryButton = forwardRef<BoxProps, CustomButtonProps>(
    (props, ref) => (
        <BaseButton
            buttonColor={{
                fill: COLORS.PRIMARY.Blue,
                hover: COLORS.BRAND.Periwinkle[900],
                text: COLORS.UTIL.Gray.WHITE,
                border: COLORS.PRIMARY.Blue,
            }}
            ref={ref}
            {...props}
        />
    ),
);

export const SecondaryButton = forwardRef<BoxProps, CustomButtonProps>(
    (props, ref) => (
        <BaseButton
            buttonColor={{
                fill: COLORS.UTIL.Gray.WHITE,
                hover: COLORS.BRAND.Periwinkle[200],
                text: COLORS.PRIMARY.Blue,
                border: COLORS.PRIMARY.Blue,
            }}
            ref={ref}
            {...props}
        />
    ),
);

export const DangerousButton = forwardRef<BoxProps, CustomButtonProps>(
    (props, ref) => (
        <SecondaryButton
            buttonColor={{
                fill: COLORS.UTIL.Gray.WHITE,
                hover: COLORS.BRAND.BloodOrange[100],
                text: COLORS.PRIMARY.Error,
                border: COLORS.PRIMARY.Error,
            }}
            ref={ref}
            {...props}
        />
    ),
);

export const OrangeButton = forwardRef<BoxProps, CustomButtonProps>(
    (props, ref) => (
        <SecondaryButton
            buttonColor={{
                fill: COLORS.PRIMARY.Warning,
                hover: COLORS.BRAND.BloodOrange[800],
                text: COLORS.UTIL.Gray.WHITE,
                border: COLORS.PRIMARY.Warning,
            }}
            ref={ref}
            {...props}
        />
    ),
);
