import useScreenSize from 'hooks/useScreenSize';
import { ReactElement } from 'react';
import { FlexRow } from 'ui/loulaFlex';
import { PXSTR } from 'ui/px';

export const PageFooter = ({ actionBtns }: { actionBtns: ReactElement[] }) => {
    const { isNarrowScreen } = useScreenSize();

    if (!isNarrowScreen) return null;

    return (
        <FlexRow
            className="pageFooter"
            width="100%"
            justifyContent="center"
            alignItems="center"
            gap={PXSTR.S}
            paddingY={PXSTR.M}
            paddingX={PXSTR.L}
        >
            {actionBtns}
        </FlexRow>
    );
};
