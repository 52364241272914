import { StyleProps, SystemStyleObject } from '@chakra-ui/react';
import { Spectrum } from 'ui/colors';

export type WaveProps = {
    sp: Spectrum;
    base?: number;
    intensity?: 1 | 2 | 3;
};

export const waves = ({
    sp,
    base = 400,
    intensity = 3,
}: WaveProps): SystemStyleObject => {
    return {
        '@keyframes placeholder-shimmer': {
            '0%': {
                backgroundPosition: '100%',
            },
            '100%': {
                backgroundPosition: '-100%',
            },
        },
        animation:
            '3s cubic-bezier(.25,.50,.75,.50) infinite placeholder-shimmer',
        backgroundSize: '200%',
        // height: '100%',
        // width: '100%',
        backgroundImage: `linear-gradient(to right, \
                  ${sp[base ?? 400]}   0%,\
                  ${sp[base !== undefined ? base + Math.max(1, intensity - 1) * 100 : 600]}  25%,\
                  ${sp[base !== undefined ? base + Math.max(1, intensity - 2) * 100 : 500]}  50%,\
                  ${sp[base !== undefined ? base + intensity * 100 : 700]}  75%,\
                  ${sp[base ?? 400]} 100%\
                )`,
    };
};

export const Waves = (
    props: WaveProps,
): StyleProps & { sx: SystemStyleObject } => {
    return {
        background: undefined,
        ...CSS(waves(props)),
    };
};

export const CSS = (props?: SystemStyleObject): { sx: SystemStyleObject } => {
    return {
        sx: props ?? {},
    };
};
