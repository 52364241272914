import { useClickOutside } from 'hooks/useClickOutside';
import {
    KeyboardEventHandler,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { AutoCompletePossibility } from 'ui/ui.models';

export type SuggestionsProps = {
    filterFn?: (_value: string, _item: string) => boolean;
    sortFn?: (
        _a: AutoCompletePossibility,
        _b: AutoCompletePossibility,
    ) => number;
    possibilities: AutoCompletePossibility[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClickItem?: (_itemValue: any) => void;
    clearOnClick?: boolean;
};

export const useAutocompleteSuggestions = ({
    value,
    filterFn,
    sortFn,
    possibilities,
    onClickItem,
    clearOnClick,
    setValue,
}: SuggestionsProps & { value: string; setValue: (_val: string) => void }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [showSuggestions, setShowSuggestions] = useState(false);

    useClickOutside(containerRef, () => setShowSuggestions(false));

    const suggestions: AutoCompletePossibility[] = useMemo(() => {
        if (value.length === 0) {
            setShowSuggestions(false);
            return [];
        }

        const simplifiedValue = value.toLowerCase().trim();

        const standardFilterFn = (_value: string, item: string) =>
            item.toLowerCase().includes(simplifiedValue);

        const standardSortFn = (
            a: AutoCompletePossibility,
            b: AutoCompletePossibility,
        ) =>
            a.value?.toString().toLowerCase().indexOf(simplifiedValue) >
            b.value?.toString().toLowerCase().indexOf(simplifiedValue)
                ? 1
                : -1;

        return possibilities
            .filter((item) => {
                setShowSuggestions(true);
                return !!filterFn
                    ? filterFn?.(value, item.label)
                    : standardFilterFn(value, item.label);
            })
            .sort(!!sortFn ? sortFn : standardSortFn);
    }, [filterFn, possibilities, sortFn, value]);

    const onClickSuggestion = (item: AutoCompletePossibility) => () => {
        setShowSuggestions(false);
        setValue(item.label);
        onClickItem?.(item.value);
        clearOnClick && setValue('');
    };

    return {
        containerRef,
        showSuggestions,
        setShowSuggestions,
        onClickSuggestion,
        suggestions,
    };
};

export const useAutocompleteFocus = ({
    isLoading,
    suggestions,
    possibilities,
    value,
    onClickSuggestion,
    permitManualEntry,
}: {
    value: string;
    isLoading: boolean | undefined;
    suggestions: AutoCompletePossibility[];
    possibilities: AutoCompletePossibility[];
    onClickSuggestion: (_item: AutoCompletePossibility) => () => void;
    permitManualEntry?: boolean;
}) => {
    const [focusedIdx, setFocusedIdx] = useState<number | null>(null);

    const isNoResults =
        !isLoading && suggestions.length === 0 && value.length > 0;

    const isFocused = (elemIdx: number) =>
        focusedIdx !== null && elemIdx === focusedIdx;

    const itemAtIndex = (
        elemIdx: number | null,
    ): AutoCompletePossibility | null =>
        focusedIdx === null ||
        isLoading ||
        value.length === 0 ||
        elemIdx === null
            ? null
            : isNoResults
              ? possibilities[elemIdx - 1]
              : suggestions[elemIdx - 1];

    const safeIncrement = (oldVal: number | null, max: number | null = null) =>
        Math.min(max === null ? possibilities.length : max, (oldVal ?? 0) + 1);

    const incrementToEnabledOrMax = (oldVal: number | null) => {
        let newIdx = safeIncrement(oldVal);

        for (let i = 0; i < 100; i++) {
            if (!itemAtIndex(newIdx)?.disabled) {
                break;
            }
            newIdx = safeIncrement(newIdx);
        }

        setFocusedIdx(newIdx);
    };

    const safeDecrement = (
        oldVal: number | null | undefined,
        min: number = 0,
    ) => Math.max(min, (oldVal ?? 0) - 1);

    const decrementToEnabledOrMin = (oldVal: number | null) => {
        let newIdx = safeDecrement(oldVal);

        for (let i = 0; i < 100; i++) {
            if (!itemAtIndex(newIdx)?.disabled) {
                break;
            }
            newIdx = safeDecrement(newIdx);
        }

        setFocusedIdx(newIdx);
    };

    const onKeyDown: KeyboardEventHandler = (e) => {
        if (e.key === 'ArrowUp') {
            decrementToEnabledOrMin(focusedIdx);
            e.preventDefault();
        } else if (e.key === 'ArrowDown') {
            incrementToEnabledOrMax(focusedIdx);
            e.preventDefault();
        } else if (e.key === 'Enter') {
            if (permitManualEntry && focusedIdx === 0) {
                onClickSuggestion({
                    label: value,
                    value: value,
                })();
                return;
            }

            const focusedItem = itemAtIndex(focusedIdx);
            if (!focusedItem) return;

            setFocusedIdx(0);
            onClickSuggestion(focusedItem)();
        }
    };

    useEffect(() => {
        setFocusedIdx(isNoResults ? 0 : 1);
    }, [value, isNoResults, setFocusedIdx]);

    return {
        isFocused,
        isNoResults,
        onKeyDown,
        focusedIdx,
        setFocusedIdx,
    };
};
