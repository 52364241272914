import COLORS from 'ui/colors';
import FONTS from 'ui/fonts';
import PX from 'ui/px';
import React, { useMemo } from 'react';
import { FlexRow } from 'ui/loulaFlex';
import { Avatar } from 'ui/components/Avatar';
import { usePractitioner } from 'hooks/dbHooks';
import { nameToShow } from 'util/Utils';

export const UserTag = ({
    overrideName,
    isLoading = false,
}: {
    overrideName?: string;
    isLoading?: boolean;
}): React.ReactElement => {
    const { practitionerData } = usePractitioner();

    const { name, initials } = useMemo(() => {
        const name = !!overrideName
            ? overrideName
            : (practitionerData?.user.firstName?.length ?? 0) > 0
              ? nameToShow(
                    practitionerData?.user.firstName,
                    practitionerData?.user.lastName,
                    practitionerData?.user.preferredName,
                )
              : practitionerData?.user.email;

        const splitName = name?.split(' ') ?? [];
        const initials =
            (splitName.length > 0 ? splitName[0].charAt(0) : '') +
            (splitName.length > 1 ? splitName[1].charAt(0) : '');

        return { name, initials };
    }, [
        overrideName,
        practitionerData?.user.email,
        practitionerData?.user.firstName,
        practitionerData?.user.lastName,
        practitionerData?.user.preferredName,
    ]);

    return (
        <FlexRow
            paddingTop={PX.SPACING.REM.SM}
            paddingLeft={[PX.SPACING.REM.M, null, 0]}
            marginBottom={[PX.SPACING.REM.M, null, 0]}
            alignItems="center"
            gap={PX.SPACING.REM.S}
            marginTop={[null, null, 'auto']}
            role="region"
            aria-label="user tag"
        >
            <Avatar
                size="lg"
                initials={isLoading ? '...' : initials}
                canLogOut={true}
            />
            <FONTS.P1
                transition="all 0.5s"
                opacity={isLoading ? 0 : 1}
                color={COLORS.UTIL.Gray[900]}
            >
                {name}
            </FONTS.P1>
        </FlexRow>
    );
};
