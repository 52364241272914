import { Flex } from '@chakra-ui/react';
import COLORS from 'ui/colors';
import FONTS from 'ui/fonts';
import PX from 'ui/px';
import React from 'react';
import { NumBadge } from 'ui/components/NumBadge';
import { Waves } from 'ui/animations/gradients';

export const NumCompleteTracker = ({
    numComplete,
    numTotal,
    isLoading = false,
}: {
    numComplete: number;
    numTotal: number;
    isLoading?: boolean;
}): React.ReactElement => {
    return (
        <Flex
            role="region"
            aria-label="number of complete steps"
            minHeight={[PX.SPACING.REM.XL, null, PX.SPACING.REM.XXL]}
            border={`1px solid ${COLORS.UTIL.Gray[500]}`}
            padding={[
                `${PX.SPACING.PX.XS} ${PX.SPACING.PX.SM}`,
                null,
                PX.SPACING.PX.M,
            ]}
            background={COLORS.UTIL.Gray.WARM}
            borderRadius={PX.RADII.LG}
            gap={PX.SPACING.REM.XS}
            alignItems="center"
            transition="all 0.3s"
            {...(isLoading
                ? Waves({
                      sp: COLORS.UTIL.Gray,
                      base: 100,
                      intensity: 2,
                  })
                : {})}
        >
            <NumBadge num={isLoading ? undefined : numComplete} />
            <FONTS.H4
                transition="all 0.5s"
                opacity={isLoading ? 0 : 1}
                color={COLORS.PRIMARY.Blue}
            >
                of {numTotal} sections completed
            </FONTS.H4>
        </Flex>
    );
};
