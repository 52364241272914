import {
    Spacer,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tooltip,
    Tr,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import FONTS from 'ui/fonts';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import PX from 'ui/px';
import { PayerModel } from 'models/schemas';
import { CheckBox } from 'ui/components/Checkbox';
import { Alert } from 'ui/components/Alert';
import COLORS from 'ui/colors';
import { Zyg } from 'util/Utils';
import { PlaceholderLoadingBar } from 'ui/components/PlaceholderLoadingBar';

export const MCPTable = ({
    selectedPayerIds,
    setSelectedPayerIds,
    allPayers,
    counties,
    isLoading = false,
}: {
    counties: string[];
    selectedPayerIds: Record<string, boolean>;
    allPayers: Record<string, PayerModel>;
    setSelectedPayerIds: (_newVal: Record<string, boolean>) => void;
    isLoading?: boolean;
}) => {
    const [allSelected, setAllSelected] = useState(
        Object.values(selectedPayerIds).every((v) => v),
    );

    useEffect(function checkAllIfNeeded() {
        if (!allSelected) return;

        if (Object.values(selectedPayerIds).every((v) => v)) return;

        setSelectedPayerIds(
            Object.fromEntries(
                Object.keys(selectedPayerIds).map((key) => [key, true]),
            ),
        );
    });

    const onClickPlanCheckbox = (payerId: string) => (isChecked: boolean) => {
        setSelectedPayerIds({ ...selectedPayerIds, [payerId]: isChecked });
    };

    const getCountiesServedText = (
        countiesServed: string[],
    ): {
        countiesListed: string;
        numExtra: number;
        fullString: string | null;
    } => {
        //sort matching ones to the front
        countiesServed.sort((a, b) =>
            counties.includes(a) ? 1 : counties.includes(b) ? -1 : 0,
        );

        const numExtra = Math.max(countiesServed.length - 2, 0);
        const countiesListed = countiesServed.slice(0, 2).join(', ');
        const fullString = numExtra > 0 ? countiesServed.join(', ') : null;

        return {
            countiesListed,
            numExtra,
            fullString,
        };
    };

    return (
        <FlexCol gap={PX.SPACING.PX.L}>
            <FONTS.H2>
                Select the Managed Care Plans you want to be credentialed with
            </FONTS.H2>

            <FlexRow align="center" gap={PX.SPACING.REM.S}>
                <CheckBox
                    isChecked={allSelected}
                    onChange={(e) => setAllSelected(e.target.checked)}
                />
                <FONTS.P1>
                    Get credentialed with all{' '}
                    <b>
                        {Object.keys(selectedPayerIds).length} Managed Care
                        Plans
                    </b>{' '}
                    in the selected counties (recommended)
                </FONTS.P1>
            </FlexRow>

            <Table size={Zyg('sm', 'md')}>
                <Thead>
                    <Tr>
                        <Th></Th>
                        <Th scope="col">
                            <FONTS.H6>Health Plan</FONTS.H6>
                        </Th>
                        <Th scope="col">
                            <FONTS.H6>Counties Served</FONTS.H6>
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {isLoading ? (
                        <LoadingRows />
                    ) : (
                        Object.values(allPayers).map((payer, idx) => {
                            if (!(payer.externalPayerId in selectedPayerIds))
                                return null;
                            const toolTipData = getCountiesServedText(
                                payer.countiesServed,
                            );
                            return (
                                <MCPTableRow
                                    key={`row_${idx}`}
                                    payer={payer}
                                    isChecked={
                                        selectedPayerIds[payer.externalPayerId]
                                    }
                                    isDisabled={allSelected}
                                    onClickCheckbox={onClickPlanCheckbox(
                                        payer.externalPayerId,
                                    )}
                                    {...toolTipData}
                                />
                            );
                        })
                    )}
                </Tbody>
            </Table>
            <Alert content="Include Managed Care Plans you have an existing contract if you want to bill that plan through Loula. Loula will only be able to assist you with plans that you are credentialed with through Loula. We are unable assist with plans where you only have an individual contract." />
        </FlexCol>
    );
};

const LoadingRows = ({ numToRender = 3 }: { numToRender?: number }) => {
    return Array(numToRender)
        .fill(0)
        .map((val, idx) => <MCPTableRow key={`tr_${idx}`} isLoading={true} />);
};

const MCPTableRow = ({
    payer,
    isChecked,
    isDisabled,
    toolTip,
    onClickCheckbox,
    countiesListed,
    numExtra,
    isLoading,
}: {
    payer?: PayerModel;
    isChecked?: boolean;
    isDisabled?: boolean;
    toolTip?: string | null;
    onClickCheckbox?: (_val: boolean) => void;
    countiesListed?: string | null;
    numExtra?: number | null;
    isLoading?: boolean;
}) => {
    return (
        <Tr opacity={isDisabled ? 0.5 : 1}>
            <Td>
                {isLoading ? (
                    <Spacer width={Zyg(PX.SPACING.PX.XL, 0)} />
                ) : (
                    <CheckBox
                        isDisabled={isDisabled}
                        isChecked={isChecked}
                        onChange={(e) => onClickCheckbox?.(e.target.checked)}
                    />
                )}
            </Td>
            <Td>
                {isLoading ? (
                    <PlaceholderLoadingBar />
                ) : (
                    <FONTS.H4>{payer?.name}</FONTS.H4>
                )}
            </Td>
            <Td>
                {isLoading ? (
                    <PlaceholderLoadingBar />
                ) : (
                    <Tooltip placement="bottom-start" label={toolTip}>
                        <FlexRow gap={PX.SPACING.PX.S}>
                            <FONTS.P1>{countiesListed}</FONTS.P1>
                            {(numExtra ?? 0) > 0 && (
                                <FONTS.P1 color={COLORS.PRIMARY.Grey}>
                                    +{numExtra} others
                                </FONTS.P1>
                            )}
                        </FlexRow>
                    </Tooltip>
                )}
            </Td>
        </Tr>
    );
};
