import {
    PayerModel,
    PractitionerReturnType,
    ProcedureCodeModel,
    ProviderContractReturnType,
    PandadocDocument,
} from '../models/schemas';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { authorize } from '../api/API';
import {
    CredentialingExternalMapping,
    CredentialingStatusSortPriority,
    OnboardingStatus,
} from 'models/constants';

export function useAllPayers() {
    const { getAccessTokenSilently } = useAuth0();
    const [payers, setPayers] = useState<Record<string, PayerModel>>({});

    useEffect(() => {
        (async () => {
            const token = await getAccessTokenSilently({});
            authorize(token)
                .get(`/payers/list`)
                .then((res): void => {
                    const payers = res.data;
                    const payersMap: Record<string, PayerModel> = {};
                    payers.forEach((payer: PayerModel) => {
                        payersMap[payer.externalPayerId] = payer;
                    });
                    setPayers(payersMap);
                });
        })();
    }, [getAccessTokenSilently]);

    return {
        allPayers: payers,
    };
}
export function useProcedureCodes() {
    const { getAccessTokenSilently } = useAuth0();

    const [procedureCodes, setProcedureCodes] = useState<ProcedureCodeModel[]>(
        [],
    );
    useEffect(() => {
        (async () => {
            const token = await getAccessTokenSilently({});
            authorize(token)
                .get(`/visits/procedureOptions`)
                .then((res): void => {
                    const procedureOptions = res.data;
                    setProcedureCodes(procedureOptions);
                });
        })();
    }, [getAccessTokenSilently]);

    return {
        procedureCodes,
    };
}

export const usePractitioner = () => {
    const { getAccessTokenSilently } = useAuth0();

    const [practitionerData, setPractitionerData] =
        useState<PractitionerReturnType | null>(null);

    const getPractitionerData = useCallback(async () => {
        const token = await getAccessTokenSilently();
        authorize(token)
            .get(`/providers/me`)
            .then((res): void => {
                setPractitionerData(res.data);
            });
    }, [getAccessTokenSilently]);

    useEffect(() => {
        (async () => {
            await getPractitionerData();
        })();
    }, [getPractitionerData]);

    return {
        practitionerData,
        refreshPractitionerData: getPractitionerData,
    };
};

export const useOnboardingStatus = (): OnboardingStatusContext => {
    const { getAccessTokenSilently } = useAuth0();

    const { practitionerData, refreshPractitionerData } = usePractitioner();
    const { onboardingStatus } = practitionerData ?? {};

    const updateOnboardingStatus = useCallback(
        async (newStatus: OnboardingStatus) => {
            const token = await getAccessTokenSilently();

            await authorize(token)
                .post('/providers/onboard/status', { status: newStatus })
                .then(refreshPractitionerData);
        },
        [getAccessTokenSilently, refreshPractitionerData],
    );

    return {
        isLoaded: practitionerData !== null,
        onboardingStatus: onboardingStatus as OnboardingStatus | undefined,
        refreshStatus: refreshPractitionerData,
        updateOnboardingStatus,
        isStarted: onboardingStatus === OnboardingStatus.STARTED,
        isCompleted:
            onboardingStatus === OnboardingStatus.APPROVED ||
            onboardingStatus === OnboardingStatus.SUBMITTED ||
            onboardingStatus === OnboardingStatus.UNDER_REVIEW,
    };
};

export type OnboardingStatusContext = {
    isLoaded: boolean;
    onboardingStatus: OnboardingStatus | undefined;
    updateOnboardingStatus: (_newStatus: OnboardingStatus) => Promise<void>;
    isStarted: boolean;
    isCompleted: boolean;
    refreshStatus: () => Promise<void>;
};

export const useProviderContracts = () => {
    const { getAccessTokenSilently } = useAuth0();

    const [providerContractData, setProviderContractData] = useState<
        ProviderContractReturnType[]
    >([]);

    const sortData = (
        a: ProviderContractReturnType,
        b: ProviderContractReturnType,
    ) => {
        if (
            CredentialingStatusSortPriority[
                CredentialingExternalMapping[
                    a.practitioner_contract_assignments.credentiallingStatus
                ]
            ] <
            CredentialingStatusSortPriority[
                CredentialingExternalMapping[
                    b.practitioner_contract_assignments.credentiallingStatus
                ]
            ]
        ) {
            return -1;
        } else {
            return 1;
        }
    };

    const getData = useCallback(async () => {
        const token = await getAccessTokenSilently();
        authorize(token)
            .get(`/contracts`)
            .then((res): void => {
                if (!Array.isArray(res.data)) {
                    // eslint-disable-next-line no-console
                    console.error(
                        'PRoviderContractReturnType was not an array',
                    );
                    return;
                }
                setProviderContractData(res.data.sort(sortData));
            });
    }, [getAccessTokenSilently]);

    useEffect(() => {
        (async () => {
            await getData();
        })();
    }, [getData]);

    const hasActionRequired = useMemo(() => {
        return providerContractData.some(
            (data) =>
                data.practitioner_contract_assignments.credentiallingStatus ===
                'action required',
        );
    }, [providerContractData]);

    return {
        providerContractData,
        hasActionRequired,
    };
};

export const usePandadocs = () => {
    const { getAccessTokenSilently } = useAuth0();

    const [pandadocsDocumentData, setPandadocsDocumentData] =
        useState<Map<string, PandadocDocument>>();

    const getData = useCallback(async () => {
        const token = await getAccessTokenSilently();
        authorize(token)
            .get(`/pandadoc/documents`)
            .then((res): void => {
                //console.log(res.data);
                const docMap = new Map();
                for (const doc of res.data) {
                    docMap.set(doc.externalPayerId, doc);
                }
                setPandadocsDocumentData(docMap);
            });
    }, [getAccessTokenSilently]);

    useEffect(() => {
        (async () => {
            await getData();
        })();
    }, [getData]);

    return {
        pandadocsDocumentData,
    };
};
