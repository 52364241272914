import { TextProps } from '@chakra-ui/react';
import COLORS from 'ui/colors';
import FONTS from 'ui/fonts';

type LinkProps = TextProps & {
    link?: string;
    size?: 'sm' | 'lg';
};

const BaseLink = ({ size = 'lg', link, children, ...props }: LinkProps) => {
    const LINKFONT = size === 'sm' ? FONTS.P1 : FONTS.P2;

    return (
        <a href={link} target="_blank">
            <LINKFONT
                as="span"
                cursor="pointer"
                textDecorationLine="underline"
                color={COLORS.PRIMARY.Blue}
                transition="all 0.1s"
                _hover={{
                    color: COLORS.BRAND.Periwinkle[700],
                }}
                {...props}
            >
                {children}
            </LINKFONT>
        </a>
    );
};
const SmallLink = (props: LinkProps) => {
    return <BaseLink size="sm" {...props} />;
};

const LargeLink = (props: LinkProps) => {
    return <BaseLink size="lg" {...props} />;
};

export const LINK = {
    Small: SmallLink,
    Large: LargeLink,
};
