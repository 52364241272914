export type Spectrum = {
    [x: string | number]: string;
};

const STROKES = {
    HEAVY: '#968E85',
};

const Gray: Spectrum = {
    50: '#FEFEFE',
    WARM: '#FEFEFE',
    WHITE: '#FEFEFE',
    100: '#FCFCFC',
    SHEET: '#FCFCFC',
    200: '#F9F9F8',
    300: '#F4F4F3',
    LIGHT: '#F4F4F3',
    400: '#EEEDEC',
    500: '#E7E5E3',
    600: '#DEDCD9',
    700: '#D4D1CE',
    PRIMARY: '#D4D1CE',
    800: '#736B64',
    900: '#312E2B',
    950: '#1B1A18',
};

const Green: Spectrum = {
    50: '#FDFEFA',
    100: '#F6FDE9',
    200: '#EBFACF',
    300: '#DCF5A9',
    400: '#C8F079',
    500: '#B0E93D',
    600: '#8AC316',
    700: '#51730D',
    800: '#364D08',
    900: '#263606',
    950: '#202E05',
};

const WarningOrange: Spectrum = {
    50: '#FFFDFB',
    100: '#FEF7F0',
    200: '#FDECDD',
    300: '#FCDDC2',
    400: '#FACAA0',
    500: '#F8B377',
    600: '#F59846',
    700: '#F2780D',
    800: '#874307',
    900: '#462304',
    950: '#301803',
};

const Red: Spectrum = {
    50: '#FFFCFB',
    100: '#FEF2F1',
    200: '#FDE2DF',
    300: '#FBCCC5',
    400: '#F9AFA5',
    500: '#F78C7E',
    600: '#F4624F',
    700: '#F03219',
    800: '#891809',
    900: '#460C04',
    950: '#300803',
};

const UTIL = {
    Gray,
    Green,
    Red,
    WarningOrange,
    Black: '#000000',
};

const Periwinkle = {
    0: '#FDFDFE',
    100: '#F6F7FB',
    200: '#EAEDF7',
    300: '#DADFF1',
    BRAND: '#DADFF1',
    400: '#C5CCE9',
    500: '#ACB6DF',
    600: '#8E9CD4',
    700: '#6B7DC7',
    800: '#2D3C77',
    900: '#161D3A',
    950: '#0E1325',
};

const BloodOrange = {
    50: '#FFFCFC',
    100: '#FEF5F2',
    200: '#FDE8E1',
    300: '#FCD7CA',
    400: '#FAC0AD',
    500: '#F7A488',
    600: '#F5845E',
    700: '#F74A24',
    BRAND: '#F74A24',
    800: '#922B09',
    900: '#481605',
    950: '#300E03',
};

const Peach = {
    50: '#FFFDFD',
    100: '#FFF8F6',
    200: '#FEF0EA',
    300: '#FEE4DA',
    BRAND: '#FEE4DA',
    400: '#FDD5C5',
    500: '#FCC3AC',
    600: '#FBAD8E',
    700: '#FA946B',
    800: '#B43807',
    900: '#521903',
    950: '#310F02',
};

const Plum = {
    50: '#FFFDFD',
    100: '#FEF6F8',
    200: '#FEEBF0',
    300: '#FDDBE4',
    400: '#FBC7D5',
    500: '#FAAEC2',
    600: '#FAAEC2',
    700: '#F66F93',
    800: '#B00B37',
    900: '#500519',
    BRAND: '#500519',
    950: '#30030F',
};

/**
 * all shades of all brand colors
 */
const BRAND = {
    Periwinkle,
    BloodOrange,
    Peach,
    Plum,
};

//?
const PRIMARY = {
    Grey: '#968E85',
    Blue: '#2D3C77',
    White: Gray.WARM,
    Warning: '#F74A24',
    Error: Red[700],
};

/**
 * TODO-Mina update this
 * Big 4 brand colors ✨
 */
const Brand = {
    Periwinkle: '#DADFF1',
    Orange: '#F25E2C',
    Peach: '#FEE4DA',
    Plum: '#500519',
};

const COLORS = {
    STROKES,

    UTIL, // All shades of all util colors
    BRAND, // All shades of all brand colors
    PRIMARY,

    Brand, // The 4 primary brand colors
};

export default COLORS;
