import { OnboardingStatus } from 'models/constants';
import { FacilityTypeCode, VISIT_TYPES } from '../api/APIConstants';
import {
    AddressInfo,
    NpiProviderResponseItem,
    PractitionerReturnType,
    ServiceLine,
    UserModel,
} from '../models/schemas';

/**
 * For use in a few different parts of the UI having to do with selection,
 * we keep a record of the string key and a boolean value, this checks that there is
 * at least one entry with a true value (meaning at least one selection).
 * Equivalent to tracking via an array and checking that length is nonzero
 */
export const StringBoolMapHasAtleastOne = (
    record: Record<string, boolean> | undefined,
) => {
    return Object.values(record ?? {}).filter((val) => val).length > 0;
};

/**
 * Mobile first array combination of two items
 * TODO replace with ordering of what happens if you pass three, 4, 5 (however many breakpoints ig)
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Zyg = (one: any, two: any): any => {
    return {
        base: one,
        sm: null,
        md: two,
        lg: two,
        xl: two,
        '2xl': two,
    };
};

export const MobileOnlyScrollable = Zyg('scroll', 'hidden');

/**
 * Returns the full name based on the provided name parts.
 * If a preferred name is available, it will be used; otherwise, the first name will be used.
 * The last name will always be included in the full name.
 * @param User: The user object containing the name parts.
 * @returns The full name.
 */
export const getNameToShow = (user?: UserModel): string => {
    if (!user) return '';

    return `${user.preferredName ? user.preferredName : user.firstName} ${user.lastName}`;
};

export const nameToShow = (
    firstName?: string,
    lastName?: string,
    preferredName?: string,
) => {
    return `${preferredName ? preferredName : (firstName ?? '')} ${lastName ?? ''}`;
};

export const generateAddressInReadableString = (
    addressInfo?: AddressInfo,
): string | null => {
    if (!addressInfo || (addressInfo.address1?.length ?? 0) === 0) return null;
    const { address1, address2, city, state, zip } = addressInfo;

    return `${address1}${(address2?.length ?? 0 > 0) ? `, ${address2}` : ''}, ${city}, ${state} ${zip}`;
};

export const onboardingComplete = (
    providerProfile: PractitionerReturnType | undefined,
): boolean => {
    if (providerProfile == null) return false;
    return providerProfile.onboardingStatus === OnboardingStatus.APPROVED;
};

export const onboardingStarted = (
    providerProfile: PractitionerReturnType | undefined,
): boolean => {
    if (providerProfile == null) return false;
    return providerProfile.onboardingStatus === OnboardingStatus.APPROVED;
};

/**
 * Converts minutes to a readable format of hours and minutes.
 * @param minutes - The number of minutes to convert.
 * @returns A string representation of the converted time in the format "X hour(s) and Y minute(s)".
 */
export function convertMinutesToReadable(minutes: number) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours} hour(s) and ${remainingMinutes} minute(s)`;
}

/**
 * Retrieves the label for a given visit type.
 * @param visitType - The visit type to search for.
 * @returns The label of the visit type, or an empty string if not found.
 */
export function getVisitTypeLabel(visitType: string): string {
    //console.log('Searching for visit type: ', visitType);
    const search = VISIT_TYPES.flatMap((group) => {
        return group.options.find((option) => option.value === visitType);
    });
    if (search.length > 0 && search[0]?.label) return search[0].label;
    return '';
}

/**
 * Retrieves the label for a given place of service code.
 * @param placeOfService - The place of service code.
 * @returns The label corresponding to the place of service code, or an empty string if the code is null or not found.
 */
export function getPlaceOfServiceLabel(placeOfService: number): string {
    if (placeOfService == null) return '';

    const search = Object.entries(FacilityTypeCode).filter(([k, v]) => {
        if (Number(v) == placeOfService) return k;
    });
    return search.length > 0 ? search[0][0] : '';
}

export function isNonEmptyArray(testValue: unknown): boolean {
    return Array.isArray(testValue) && testValue.length > 0;
}

export const getTotalChargesString = (
    serviceLines?: ServiceLine[],
    formatToDollars: boolean = true,
) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const total =
        serviceLines?.map((line) => line.charges).reduce((a, b) => a + b) ?? 0;
    return formatToDollars ? formatter.format(total) : total.toString();
};

export const getReadableStringFromUnderscored = (underscored: string) => {
    return underscored.replace(/_/g, ' ');
};

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function readableFileSize(bytes: number, si = true, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (
        Math.round(Math.abs(bytes) * r) / r >= thresh &&
        u < units.length - 1
    );

    return bytes.toFixed(dp) + ' ' + units[u];
}

export const ReadableFromCamelCase = (camelCaseString: string) => {
    // TODO: remove hack
    const intermediate = camelCaseString
        .replace(/([A-Z])/g, ' $1')
        .replace('T I N', 'TIN');
    return intermediate.charAt(0).toUpperCase() + intermediate.slice(1);
};

export const TitleFromLowerCase = (lowercase: string | undefined) => {
    if (!lowercase) return '';

    const elems = lowercase.split(/[\s-_]/); // Split on space, -, or _
    for (let i = 0; i < elems.length; i++) {
        elems[i] = elems[i].charAt(0).toUpperCase() + elems[i].substring(1);
    }
    // Directly return the joined string
    return elems.join(' ');
};

export const FormatProviderNPIName = (npi: NpiProviderResponseItem) => {
    return FormatNameCapitalization(
        npi.basic.first_name +
            (!!npi.basic.middle_name ? ' ' + npi.basic.middle_name : '') +
            ' ' +
            npi.basic.last_name,
    );
};

export const FormatNPIAddress = (npi: NpiProviderResponseItem) => {
    return (
        npi.addresses[0].city +
        ', ' +
        npi.addresses[0].state +
        ' ' +
        npi.addresses[0].postal_code.slice(0, 5) +
        '-' +
        npi.addresses[0].postal_code.slice(5)
    );
};

export const FormatNameCapitalization = (rawString: string): string => {
    const words = rawString.split(' ');

    for (let i = 0; i < words.length; i++) {
        const rawWord = words[i].trim();
        words[i] = rawWord[0].toUpperCase() + rawWord.substr(1).toLowerCase();
    }

    return words.join(' ');
};

export const DefaultStringifiedDate = (
    date: Date,
    showFullYear: boolean = true,
): string => {
    let day: string | number = date.getDate();
    let month: string | number = date.getMonth() + 1;
    const fullYear = date.getFullYear().toString();

    if (day < 10) day = '0' + day;
    if (month < 10) month = '0' + month;

    return [month, day, showFullYear ? fullYear : fullYear.slice(2, 4)].join(
        '/',
    );
};

export const DefaultDateFromString = (str: string): Date => {
    const monthDayYear = str.split('/').map((stringSub) => Number(stringSub));
    return new Date(monthDayYear[2], monthDayYear[0] - 1, monthDayYear[1]);
};
