import { ReactElement, ReactNode, useState } from 'react';
import { NavBar } from './components/NavBar';
import COLORS from 'ui/colors';
import { ColRow, FlexCol } from 'ui/loulaFlex';

import { Sheet } from 'ui/components/Sheet';
import { Collapse } from '@chakra-ui/react';
import useScreenSize from 'hooks/useScreenSize';
import { PAGE_COLLAPSE_DURATION } from 'models/constants/animationConstants';
import { HEADER_MIN_HEIGHT, PageHeader } from './components/PageHeader';
import { PageFooter } from './components/PageFooter';
import { PXSTR } from 'ui/px';

export const DoulaPortalLayout = ({
    pageHeader,
    pageActionButtons,
    children,
}: {
    pageHeader: string;
    pageActionButtons: ReactElement[];
    children?: ReactNode;
}) => {
    const [isSheetVisible, setIsSheetVisible] = useState<boolean>(true);

    const { isNarrowScreen } = useScreenSize();

    const SheetComponent = (
        <Sheet
            role="main"
            className="mainPageSheet"
            height="100%"
            borderRadius={PXSTR.SM}
        >
            <FlexCol
                borderRadius="inherit"
                width="100%"
                height="100%"
                justifyContent="space-between"
                alignItems="center"
            >
                <PageHeader
                    text={pageHeader}
                    actionBtns={pageActionButtons}
                    onMobileClick={() => setIsSheetVisible(true)}
                />

                <FlexCol
                    width="100%"
                    justifyContent="center"
                    className="internalPageWrapper"
                    flex={1}
                >
                    {children}
                </FlexCol>

                <PageFooter actionBtns={pageActionButtons} />
            </FlexCol>
        </Sheet>
    );

    return (
        <ColRow
            w="100vw"
            h="100vh"
            position="fixed"
            overflow="hidden"
            className="portalTopLevelLayout"
            background={COLORS.UTIL.Gray[200]}
        >
            <NavBar
                isSheetVisible={isSheetVisible}
                setIsSheetVisible={setIsSheetVisible}
            />
            {isNarrowScreen ? (
                <Collapse
                    startingHeight={HEADER_MIN_HEIGHT}
                    endingHeight={`calc(100% - ${HEADER_MIN_HEIGHT}px)`}
                    in={isSheetVisible}
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                    }}
                    transition={{
                        enter: { duration: PAGE_COLLAPSE_DURATION / 2 },
                        exit: { duration: PAGE_COLLAPSE_DURATION / 2 },
                    }}
                >
                    {SheetComponent}
                </Collapse>
            ) : (
                SheetComponent
            )}
        </ColRow>
    );
};
