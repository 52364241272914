import { chakra, CheckboxProps, useCheckbox } from '@chakra-ui/react';
import { useChildInputRef } from 'hooks/useChildInputRef';
import COLORS from 'ui/colors';
import FONTS from 'ui/fonts';
import { ICONS } from 'ui/icons';
import { FlexRow } from 'ui/loulaFlex';
import PX from 'ui/px';

export const CheckBox = ({
    label,
    ...props
}: CheckboxProps & { label?: string }) => {
    const { state, htmlProps, getInputProps, getCheckboxProps } = useCheckbox({
        ...props,
        'aria-describedby': 'checkboxLabelElement',
    });

    const checkboxProps = getCheckboxProps();
    const { childInputRef, topLevelProps } = useChildInputRef();

    return (
        <chakra.label
            display="flex"
            flexDirection="row"
            position="relative"
            gap={PX.SPACING.PX.XS}
            alignItems="center"
            cursor="pointer"
            {...htmlProps}
            tabIndex={0}
            {...topLevelProps}
        >
            <input {...getInputProps({})} ref={childInputRef} hidden />
            <FlexRow
                width={PX.SPACING.PX.XL}
                height={PX.SPACING.PX.XL}
                background="transparent"
                borderRadius={PX.RADII.ROUNDED}
                justify="center"
                align="center"
                transition="all 0.3s"
                _hover={{
                    background: COLORS.BRAND.Periwinkle[200],
                }}
            >
                <FlexRow
                    {...checkboxProps}
                    _disabled={{
                        opacity: 0.5,
                    }}
                    width={PX.SPACING.REM.M}
                    height={PX.SPACING.REM.M}
                    justify="center"
                    alignItems="center"
                    borderRadius={PX.RADII.SM}
                    transition="all 0.3s"
                    border={`1px solid ${COLORS.PRIMARY.Grey}`}
                    _active={{
                        border: `1px solid ${COLORS.BRAND.Periwinkle[700]}`,
                    }}
                >
                    <ICONS.ThinCheckmark
                        style={{ transition: 'all 0.15s' }}
                        opacity={state.isChecked ? 1 : 0}
                        size={16}
                    />
                </FlexRow>
            </FlexRow>

            <FONTS.P1 id="checkboxLabelElement">{label}</FONTS.P1>

            {props.children}
        </chakra.label>
    );
};
