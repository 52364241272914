import {
    Box,
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useDisclosure,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { FieldValues, UseFormSetValue, useForm } from 'react-hook-form';
import { authorize } from '../api/API';
import { useAuth0 } from '@auth0/auth0-react';
import { TextInput } from 'ui/components/TextInput';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import COLORS from 'ui/colors';
import PX from 'ui/px';
import SHADOWS from 'ui/shadows';
import FONTS from 'ui/fonts';
import { PrimaryButton, SecondaryButton } from 'ui/components/Button';
import { NpiProviderResponseItem } from 'models/schemas';
import { IconButton } from 'ui/components/IconButton';
import { FormatNPIAddress, FormatProviderNPIName } from 'util/Utils';
import useScreenSize from 'hooks/useScreenSize';
import { Alert } from 'ui/components/Alert';
import { FormCheckboxInput } from 'ui/components/Form/FormCheckBoxInput';

interface NPISearchProps {
    setValue: UseFormSetValue<FieldValues> | undefined;
    initialValue: string;
}

const NPISearch = (props: NPISearchProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const { isNarrowScreen } = useScreenSize();

    const {
        search,
        back,
        selectNpi,
        closeAndReset,
        firstNameWatch,
        lastNameWatch,
        register,
        setFormValue,
        formInstance,
        watch,
        npiData,
        error,
        isLoading,
        showResults,
        selectedNpi,
    } = useNPISearchForm(props);

    return (
        <>
            <TextInput
                value={selectedNpi}
                isReadOnly={true}
                label="National Provider Identification (NPI) Number"
                subLabel="Use the lookup above to find your individual NPI number."
                iconRight="Search"
                onClick={() => onOpen()}
            />

            <Modal
                isOpen={isOpen}
                onClose={() => {
                    onClose();
                    closeAndReset();
                }}
                size={'3xl'}
            >
                <ModalOverlay />
                <ModalContent
                    transition="all 0.3s"
                    maxWidth={showResults ? '692px' : '500px'}
                >
                    <FlexCol
                        background={COLORS.UTIL.Gray.WARM}
                        borderRadius={PX.RADII.LG}
                        boxShadow={SHADOWS.elevationOne}
                        width="100%"
                    >
                        <FlexRow
                            align="center"
                            paddingX={PX.SPACING.REM.L}
                            paddingY={PX.SPACING.REM.M}
                            justify="space-between"
                        >
                            <FONTS.H2>NPI Look-up</FONTS.H2>
                            <ModalCloseButton>
                                <IconButton icon="Close" size={20} />
                            </ModalCloseButton>
                        </FlexRow>

                        {!showResults ? (
                            <FlexCol width="100%">
                                <FlexCol
                                    gap={PX.SPACING.REM.M}
                                    padding={PX.SPACING.REM.L}
                                    borderY={`1px solid ${COLORS.UTIL.Gray[500]}`}
                                >
                                    <FONTS.H3>
                                        Enter your first and last names to
                                        search the Nation Provider
                                        Identification (NPI) database
                                    </FONTS.H3>
                                    <FlexRow
                                        gap={PX.SPACING.REM.L}
                                        justify="space-between"
                                        wrap="wrap"
                                        width="100%"
                                    >
                                        <Box flex={1}>
                                            <TextInput
                                                id="npiFirstName"
                                                label="First Name"
                                                {...register('firstName')}
                                            />
                                        </Box>
                                        <Box flex={1}>
                                            <TextInput
                                                flex={1}
                                                id="npiLastName"
                                                label="Last name"
                                                {...register('lastName')}
                                            />
                                        </Box>
                                    </FlexRow>
                                    <FormCheckboxInput
                                        defaultChecked={true}
                                        fieldName="isLimitedToState"
                                        label="Only search for providers in California"
                                        watch={watch}
                                        setValue={setFormValue}
                                        errors={formInstance.formState.errors}
                                    />
                                    {error && (
                                        <Alert content="No results found. Please try again." />
                                    )}
                                </FlexCol>
                                <FlexRow
                                    justify="end"
                                    paddingX={PX.SPACING.REM.L}
                                    paddingY={PX.SPACING.REM.M}
                                    gap={PX.SPACING.REM.S}
                                >
                                    <SecondaryButton onClick={onClose}>
                                        Cancel
                                    </SecondaryButton>
                                    <PrimaryButton
                                        isLoading={isLoading}
                                        disabled={isLoading}
                                        onClick={search}
                                    >
                                        Search
                                    </PrimaryButton>
                                </FlexRow>
                            </FlexCol>
                        ) : (
                            <FlexCol
                                width="100%"
                                gap={PX.SPACING.REM.M}
                                padding={PX.SPACING.REM.L}
                                borderTop={`1px solid ${COLORS.UTIL.Gray[500]}`}
                            >
                                <FlexRow gap={PX.SPACING.PX.S} align="center">
                                    <IconButton
                                        aria-label="back"
                                        onClick={back}
                                        icon="ArrowBackward"
                                    />
                                    <FONTS.P1>Back</FONTS.P1>
                                </FlexRow>
                                <FONTS.H3>
                                    {npiData.length > 0
                                        ? `${npiData.length} result${npiData.length > 1 ? 's' : ''} for "${firstNameWatch}${lastNameWatch?.length > 0 ? ` ${lastNameWatch}` : ''}"`
                                        : 'No results found'}
                                </FONTS.H3>
                                <FlexCol overflowY="scroll" maxHeight="320px">
                                    {npiData.length > 0 && isNarrowScreen ? (
                                        <FlexCol>
                                            {npiData.map((npi, idx) => (
                                                <FlexCol
                                                    key={`npi_res_${npi.number}_${idx}`}
                                                    gap={PX.SPACING.PX.S}
                                                    borderY={`1px solid ${COLORS.UTIL.Gray[500]}`}
                                                    paddingY={PX.SPACING.PX.SM}
                                                >
                                                    <FONTS.P1>
                                                        {npi.number}
                                                    </FONTS.P1>

                                                    <FONTS.P1>
                                                        {FormatProviderNPIName(
                                                            npi,
                                                        )}
                                                    </FONTS.P1>

                                                    <FONTS.P1>
                                                        {
                                                            npi.addresses[0]
                                                                .address_1
                                                        }
                                                        {FormatNPIAddress(npi)}
                                                    </FONTS.P1>

                                                    <PrimaryButton
                                                        onClick={selectNpi(
                                                            npi.number,
                                                        )}
                                                        id={`select-npi-${idx}`}
                                                    >
                                                        Use
                                                    </PrimaryButton>
                                                </FlexCol>
                                            ))}
                                        </FlexCol>
                                    ) : (
                                        <Table>
                                            <Thead>
                                                <Th padding={0}>
                                                    <FONTS.H6>NPI</FONTS.H6>
                                                </Th>
                                                <Th padding={0}>
                                                    <FONTS.H6>NAME</FONTS.H6>
                                                </Th>
                                                <Th padding={0} width="45%">
                                                    <FONTS.H6>
                                                        Primary Practice Address
                                                    </FONTS.H6>
                                                </Th>
                                                <Th></Th>
                                            </Thead>
                                            <Tbody>
                                                {npiData.map((npi, index) => (
                                                    <Tr
                                                        key={`npi_res_${index}`}
                                                    >
                                                        <Td
                                                            padding={0}
                                                            paddingRight={
                                                                PX.SPACING.PX.M
                                                            }
                                                        >
                                                            {npi.number}
                                                        </Td>
                                                        <Td
                                                            padding={0}
                                                            paddingRight={
                                                                PX.SPACING.PX.M
                                                            }
                                                        >
                                                            {FormatProviderNPIName(
                                                                npi,
                                                            )}
                                                        </Td>
                                                        <Td padding={0}>
                                                            <FONTS.P1>
                                                                {
                                                                    npi
                                                                        .addresses[0]
                                                                        .address_1
                                                                }
                                                            </FONTS.P1>
                                                            <FONTS.P1>
                                                                {FormatNPIAddress(
                                                                    npi,
                                                                )}
                                                            </FONTS.P1>
                                                        </Td>
                                                        <Td
                                                            padding={
                                                                PX.SPACING.PX.S
                                                            }
                                                        >
                                                            <PrimaryButton
                                                                onClick={selectNpi(
                                                                    npi.number,
                                                                )}
                                                                id={`select-npi-${index}`}
                                                            >
                                                                Use
                                                            </PrimaryButton>
                                                        </Td>
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    )}
                                </FlexCol>
                            </FlexCol>
                        )}
                    </FlexCol>
                </ModalContent>
            </Modal>
        </>
    );
};

const useNPISearchForm = ({ setValue, initialValue }: NPISearchProps) => {
    const [npiData, setNpiData] = useState<NpiProviderResponseItem[]>([]);
    const [selectedNpi, setSelectedNpi] = useState(initialValue);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showResults, setShowResults] = useState(false);

    const {
        register,
        getValues,
        watch,
        setValue: setFormValue,
        ...formInstance
    } = useForm();

    const { getAccessTokenSilently } = useAuth0();

    const firstNameWatch = watch('firstName', '');
    const lastNameWatch = watch('lastName', '');

    const loadNPIs = async (data: FieldValues) => {
        const token = await getAccessTokenSilently({});

        let url = `/npi-registry/provider-npi?firstName=${data.firstName}&lastName=${data.lastName}`;

        if (data.isLimitedToState !== false) {
            url += `&stateAcronym=CA`;
        }

        authorize(token)
            .get(url)
            .then((res) => {
                setIsLoading(false);
                if (res.data.length === 0) {
                    setError('No results found');
                } else {
                    setError(null);
                    setNpiData(res.data);
                    setShowResults(true);
                }
            });
    };

    const search = async () => {
        const data = getValues();
        setIsLoading(true);
        setError(null);
        await loadNPIs(data);
    };

    const back = () => {
        setShowResults(false);
    };

    const selectNpi = (npi: string) => () => {
        setValue?.('npi', npi);
        setSelectedNpi(npi);
        closeAndReset();
    };

    const closeAndReset = () => {
        setShowResults(false);
        setNpiData([]);
        setError(null);
        formInstance.reset();
    };

    return {
        search,
        back,
        selectNpi,
        selectedNpi,
        closeAndReset,
        firstNameWatch,
        lastNameWatch,
        register,
        setFormValue,
        formInstance,
        watch,
        npiData,
        error,
        isLoading,
        showResults,
    };
};

export default NPISearch;
