import useScreenSize from 'hooks/useScreenSize';
import { ReactElement } from 'react';
import COLORS from 'ui/colors';
import FONTS from 'ui/fonts';
import { FlexRow } from 'ui/loulaFlex';
import { PXSTR } from 'ui/px';

export const HEADER_MIN_HEIGHT = 80;

export const PageHeader = ({
    text,
    actionBtns,
    onMobileClick,
}: {
    text: string;
    actionBtns: ReactElement[];
    onMobileClick: () => void;
}) => {
    const { isNarrowScreen } = useScreenSize();

    return (
        <FlexRow
            className="pageHeader"
            minHeight={`${HEADER_MIN_HEIGHT}px`}
            width="100%"
            justifyContent="space-between"
            alignSelf="start"
            padding={PXSTR.L}
            onClick={isNarrowScreen ? onMobileClick : undefined}
        >
            <FONTS.H2 color={COLORS.PRIMARY.Blue}>{text}</FONTS.H2>
            {!isNarrowScreen && <FlexRow gap={PXSTR.S}>{actionBtns}</FlexRow>}
        </FlexRow>
    );
};
