const RADII = {
    NONE: '0rem',
    SM: '0.125rem',
    BASE: '0.25rem',
    MD: '0.375rem',
    LG: '0.5rem',
    XL: '0.75rem',
    XXL: '1rem',
    XXXL: '2rem',
    FULL: '50%',
    ROUNDED: '1000px',
};

const PXNUM = {
    XS: 4,
    S: 8,
    SM: 12,
    M: 16,
    L: 24,
    XL: 32,
    XXL: 48,
    XXXL: 64,
    XXXXL: 96,
};

export const PXSTR = {
    XS: `${PXNUM.XS}px`,
    S: `${PXNUM.S}px`,
    SM: `${PXNUM.SM}px`,
    M: `${PXNUM.M}px`,
    L: `${PXNUM.L}px`,
    XL: `${PXNUM.XL}px`,
    XXL: `${PXNUM.XXL}px`,
    XXXL: `${PXNUM.XXXL}px`,
    XXXXL: `${PXNUM.XXXXL}px`,
};

const REMNUM = {
    XXXS: 0.25,
    XXS: 0.5,
    XS: 0.75,
    S: 0.875,
    SM: 1,
    M: 1.25,
    L: 1.5,
    XL: 2,
    XXL: 3,
};

const REMSTR = {
    XXS: `${REMNUM.XXS}rem`,
    XS: `${REMNUM.XS}rem`,
    S: `${REMNUM.S}rem`,
    SM: `${REMNUM.SM}rem`,
    M: `${REMNUM.M}rem`,
    L: `${REMNUM.L}rem`,
    XL: `${REMNUM.XL}rem`,
    XXL: `${REMNUM.XXL}rem`,
};

export const SPACING = {
    PX: PXSTR,
    REM: REMSTR,
};

const NUM = {
    PX: PXNUM,
    REM: REMNUM,
};

const PX = {
    RADII,
    SPACING,
    NUM,
};

export default PX;
