import { ReactElement, useCallback, useEffect, useState } from 'react';
import { authorize } from '../api/API';
import { useAuth0 } from '@auth0/auth0-react';
import Dropzone from './Dropzone';
import { UseFormReturn } from 'react-hook-form';
import { FileUpload, OnboardingFormDataModel } from 'models/schemas';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import COLORS from 'ui/colors';
import PX, { PXSTR } from 'ui/px';
import FONTS from 'ui/fonts';
import { readableFileSize } from 'util/Utils';
import { ProgressBar } from 'ui/components/ProgressBar';
import { useToast } from 'ui/useToast';
import { IconButton } from 'ui/components/IconButton';
import { ICONS } from 'ui/icons';
import { Collapse, SlideFade } from '@chakra-ui/react';

export interface FileUploadProps {
    label?: string;
    subLabel?: string;
    sublabelComponent?: ReactElement;
    onLoadingChange?: (_loading: boolean) => void; //In case a parent needs to know when file is being loaded
    fieldName: keyof OnboardingFormDataModel;
    maxFiles?: number;
    fieldValue: FileUpload[] | undefined;
    ['data-cy']?: string;
    formInstance: UseFormReturn | undefined;
    saveField?: (
        _field: keyof OnboardingFormDataModel,
        _value: unknown,
    ) => Promise<void>;
}

type UploaderFile = FileUpload & {
    id: string;
    originalFile?: File;
    isUploading: boolean;
};

//TODO: label and sublabel should be able to be passed as reactelements not just text (maybe instead of text?)
const FileUploader = ({
    label,
    subLabel,
    maxFiles = 0, // unlimited files
    fieldValue,
    formInstance,
    fieldName,
    sublabelComponent,
    ...props
}: FileUploadProps) => {
    const { setValue } = formInstance ?? {};
    const toast = useToast();

    const [files, setFiles] = useState<UploaderFile[]>(
        fieldValue?.map((file: FileUpload) => {
            return {
                ...file,
                isUploading: false,
                id: (Date.now() + Math.random()).toString(),
            };
        }) ?? [],
    );

    const [percentCompleted, setPercentCompleted] = useState<
        Record<string, number>
    >({});

    const { getAccessTokenSilently } = useAuth0();
    const token = getAccessTokenSilently();

    const uploadFileFn = async (
        token: string,
        file: File,
        internalId: string,
    ): Promise<FileUpload> => {
        const formData = new FormData();
        formData.append('file', file);

        const result = await authorize(token).post(
            '/providers/upload-temp-file',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) /
                            (progressEvent.total ?? 1),
                    );
                    setPercentCompleted((prev) => {
                        return {
                            ...prev,
                            [internalId]: percentCompleted,
                        };
                    });
                },
            },
        );

        return result.data as FileUpload;
    };

    const onDrop = useCallback(
        async (acceptedFiles: File[]) => {
            const acceptedFileUploads = acceptedFiles.map((file) => {
                {
                    return {
                        temporaryFileName: file.name,
                        originalFileName: file.name,
                        fileSize: file.size,
                        isUploading: true,
                        originalFile: file,
                        id: (Date.now() + Math.random()).toString(),
                    };
                }
            });

            setFiles((prev) => {
                return [...prev, ...acceptedFileUploads];
            });

            const uploadedFiles: Array<FileUpload> = [];

            for (let i = 0; i < acceptedFileUploads.length; i++) {
                const file = acceptedFileUploads[i];
                await uploadFileFn(
                    await token,
                    file.originalFile,
                    file.id,
                ).then((res): void => {
                    uploadedFiles.push(res);

                    setFiles((prev) => {
                        return prev.map((uploaderFile) => {
                            if (uploaderFile.id === file.id) {
                                return {
                                    ...uploaderFile,
                                    temporaryFileName: res.temporaryFileName,
                                    isUploading: false,
                                };
                            }
                            return uploaderFile;
                        });
                    });
                });
            }

            if (Array.isArray(fieldValue)) {
                setValue?.(fieldName, [...fieldValue, ...uploadedFiles], {
                    shouldDirty: true,
                });
            } else {
                setValue?.(fieldName, uploadedFiles, { shouldDirty: true });
            }

            toast({
                description: `${uploadedFiles.length > 1 ? `${uploadedFiles.length} files` : 'File'} uploaded`,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        },
        [token, fieldValue, setValue, fieldName, toast],
    );

    const deleteFile = (fileIdForUploader: string) => {
        setFiles((prev) => {
            const updatedValue: Array<FileUpload> = [];
            const updatedFiles: UploaderFile[] = [];

            prev.forEach((file) => {
                if (file.id === fileIdForUploader) return;
                updatedFiles.push(file);
                updatedValue.push({
                    originalFileName: file.originalFileName,
                    temporaryFileName: file.temporaryFileName,
                    fileSize: file.fileSize,
                });
            });

            setValue?.(fieldName, updatedValue, { shouldDirty: true });

            return updatedFiles;
        });

        toast({
            description: 'File deleted',
            status: 'success',
            duration: 5000,
            isClosable: true,
        });
    };

    useEffect(
        //If this component's internal loading state changes,
        //notify the parent's listener if any exists
        function handleLoadingChange() {},
        [files],
    );

    const showDropZone = maxFiles == 0 || files.length < maxFiles;

    return (
        <FlexCol gap={PX.RADII.LG}>
            <FlexCol gap={PX.SPACING.PX.S}>
                {label && <FONTS.H2>{label}</FONTS.H2>}
                {subLabel && <FONTS.P2>{subLabel}</FONTS.P2>}
                {sublabelComponent}
                <Collapse in={showDropZone} animateOpacity={true}>
                    <Dropzone
                        data-cy={props['data-cy']}
                        maxFiles={maxFiles}
                        onDropAccepted={onDrop}
                    />
                </Collapse>
            </FlexCol>

            <SlideFade in={files.length > 0} offsetY={PX.SPACING.PX.XL}>
                <FlexCol gap={PX.SPACING.PX.S}>
                    {files.map((file, index) => (
                        <UploadFileCard
                            fileName={file.originalFileName}
                            fileSize={file.fileSize}
                            tempFileName={file.temporaryFileName}
                            key={`upload_file_card_${index}`}
                            isUploading={file.isUploading}
                            percentUploaded={percentCompleted[file.id]}
                            deleteFile={() => deleteFile(file.id)}
                        />
                    ))}
                </FlexCol>
            </SlideFade>
        </FlexCol>
    );
};

export const UploadFileCard = ({
    fileName,
    fileSize,
    tempFileName,
    isUploading = false,
    deleteFile,
    error,
    percentUploaded,
}: {
    fileName: string;
    fileSize: number;
    tempFileName: string;
    isUploading?: boolean;
    deleteFile?: () => void;
    error?: string;
    percentUploaded?: number; //0-100
}) => {
    const { getAccessTokenSilently } = useAuth0();
    const token = getAccessTokenSilently();

    const viewFile = async () => {
        const res = await authorize(await token).get(
            '/providers/view-temp-file',
            {
                params: {
                    fileName: tempFileName,
                },
            },
        );

        if (res.data?.url) {
            const url = res.data.url;
            window.open(url, '_blank');
        }
    };

    return (
        <FlexCol
            width="100%"
            height="100%"
            gap={PX.SPACING.REM.M}
            maxW="400px"
            justify="space-evenly"
            padding={PX.SPACING.REM.M}
            borderRadius={PX.RADII.SM}
            border={`1px solid ${COLORS.UTIL.Gray[500]}`}
            transition="all 0.3s"
            background={COLORS.UTIL.Gray.WARM}
            cursor="pointer"
            _hover={{
                background: COLORS.UTIL.Gray[200],
                boxShadow: `1px 1px 3px ${COLORS.STROKES.HEAVY}`,
            }}
            _active={{
                background: COLORS.UTIL.Gray[300],
                boxShadow: 'none',
            }}
            onClick={viewFile}
        >
            <FlexRow width="100%" alignItems="start" gap={PX.SPACING.PX.S}>
                <ICONS.File
                    style={{ marginTop: PXSTR.XS, color: COLORS.PRIMARY.Blue }}
                    size={16}
                />
                <FlexCol maxWidth="80%" flex={1} gap={PX.SPACING.PX.XS}>
                    <FONTS.P1
                        color={COLORS.UTIL.Black}
                        overflowWrap="break-word"
                    >
                        {fileName}
                    </FONTS.P1>

                    <FONTS.P1 color={COLORS.PRIMARY.Grey}>
                        {readableFileSize(fileSize)}
                    </FONTS.P1>
                </FlexCol>
                {deleteFile && (
                    <IconButton
                        marginLeft="auto"
                        aria-label="Delete File"
                        onClick={(e) => {
                            e.stopPropagation();
                            deleteFile();
                        }}
                        icon="Delete"
                        size={20}
                        color={COLORS.STROKES.HEAVY}
                        hoverColor={COLORS.UTIL.Gray[800]}
                    />
                )}
            </FlexRow>
            {!error && isUploading && (
                <ProgressBar total={100} current={percentUploaded ?? 0} />
            )}
            {error && (
                <FONTS.P1 color={COLORS.UTIL.Red[500]}>
                    Error uploading file
                </FONTS.P1>
            )}
        </FlexCol>
    );
};

export default FileUploader;
