import { Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react';
import { TextTag, TextTagColorSchemes } from 'ui/components/TextTag';
import { usePandadocs } from 'hooks/dbHooks';
import {
    CredentialingExternalMapping,
    CredentiallingExternalStatus,
} from 'models/constants';
import { ColRow, RowCol } from 'ui/loulaFlex';
import { SecondaryButton } from 'ui/components/Button';
import PX from 'ui/px';
import { ProviderContractReturnType } from 'models/schemas';
import FONTS from 'ui/fonts';
import COLORS from 'ui/colors';
import { DefaultStringifiedDate } from 'util/Utils';

const StatusToColorSchemeMapping = {
    [CredentiallingExternalStatus.Processing]: TextTagColorSchemes.DEFAULT,
    [CredentiallingExternalStatus.UnderReview]: TextTagColorSchemes.BLUE_LIGHT,
    [CredentiallingExternalStatus.ActionNeeded]:
        TextTagColorSchemes.ORANGE_LIGHT,
    [CredentiallingExternalStatus.Credentialed]:
        TextTagColorSchemes.GREEN_LIGHT,
    [CredentiallingExternalStatus.Removed]: TextTagColorSchemes.DEFAULT,
};

export const CredentialStatusTable = ({
    providerContractData,
}: {
    providerContractData: ProviderContractReturnType[];
}) => {
    const { pandadocsDocumentData } = usePandadocs();

    return (
        <Table variant="minimalist">
            <Thead>
                <Tr>
                    <Th w="25%" scope="col">
                        Status
                    </Th>
                    <Th scope="col">Plan Name</Th>
                </Tr>
            </Thead>
            <Tbody>
                {providerContractData.map((contractData, idx) => (
                    <Tr key={`contract_status_${idx}`}>
                        <Td>
                            <TextTag
                                width="fit-content"
                                colorScheme={
                                    StatusToColorSchemeMapping[
                                        CredentialingExternalMapping[
                                            contractData
                                                .practitioner_contract_assignments
                                                .credentiallingStatus
                                        ]
                                    ]
                                }
                                text={
                                    CredentialingExternalMapping[
                                        contractData
                                            .practitioner_contract_assignments
                                            .credentiallingStatus
                                    ]
                                }
                            />
                        </Td>
                        <Td maxW="85%">
                            <ColRow
                                maxW="100%"
                                justify="space-between"
                                align="center"
                                wrap="wrap"
                            >
                                <RowCol gap={PX.SPACING.PX.S}>
                                    {contractData.payers.name}

                                    {CredentialingExternalMapping[
                                        contractData
                                            .practitioner_contract_assignments
                                            .credentiallingStatus
                                    ] ===
                                        CredentiallingExternalStatus.ActionNeeded &&
                                        pandadocsDocumentData?.has(
                                            contractData.payers.externalPayerId,
                                        ) && (
                                            <SecondaryButton
                                                iconRight="Launch"
                                                onClick={() => {
                                                    const { sharedLink } =
                                                        pandadocsDocumentData.get(
                                                            contractData.payers
                                                                .externalPayerId,
                                                        ) ?? {};
                                                    if (sharedLink)
                                                        window
                                                            .open(
                                                                sharedLink,
                                                                '_blank',
                                                            )
                                                            ?.focus();
                                                }}
                                            >
                                                Sign & attest
                                            </SecondaryButton>
                                        )}
                                </RowCol>

                                <ExtraStatusInfo contractData={contractData} />
                            </ColRow>
                        </Td>
                    </Tr>
                ))}
            </Tbody>
        </Table>
    );
};

const ExtraStatusInfo = ({
    contractData,
}: {
    contractData: ProviderContractReturnType;
}) => {
    const externalStatus =
        CredentialingExternalMapping[
            contractData.practitioner_contract_assignments.credentiallingStatus
        ];

    let textInfo = 'Extra Info';

    if (
        externalStatus === CredentiallingExternalStatus.Processing ||
        externalStatus === CredentiallingExternalStatus.ActionNeeded
    ) {
        return null;
    } else if (externalStatus === CredentiallingExternalStatus.Credentialed) {
        const effectiveDate = new Date(
            contractData.practitioner_contract_assignments.effectiveDate,
        );
        textInfo = `Plan effective starting ${DefaultStringifiedDate(effectiveDate, false)}`;
    } else if (externalStatus === CredentiallingExternalStatus.UnderReview) {
        const signaturesReceivedDate = new Date(
            contractData.practitioner_contract_assignments.signaturesReceivedDate,
        );
        textInfo = `Signatures received on ${DefaultStringifiedDate(signaturesReceivedDate, false)}`;
    }

    return <FONTS.P1 color={COLORS.PRIMARY.Grey}>{textInfo}</FONTS.P1>;
};
