import { Flex } from '@chakra-ui/react';
import { COUNTIES } from 'models/constants';
import { StepComponentProps } from 'onboarding/onboarding.model';
import {
    ReactElement,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import FONTS from 'ui/fonts';
import { AutoComplete } from 'ui/components/AutoComplete';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import { TextTag } from 'ui/components/TextTag';
import PX from 'ui/px';
import { useAllPayers } from 'hooks/dbHooks';
import { PrimaryButton } from 'ui/components/Button';
import { Radio } from 'ui/components/Radio';
import { OnboardingContext } from 'onboarding/OnboardingContext';
import { Zyg } from 'util/Utils';
import { MCPTable } from '../MCPTable';
import { AutoCompletePossibility } from 'ui/ui.models';
import { useRadioGroup } from 'ui/components/hooks/useRadioGroup';

export const ServiceAreaStep = ({
    commonStyling,
}: StepComponentProps): ReactElement => {
    const onboardingContext = useContext(OnboardingContext);

    const { formData, formInstance } = onboardingContext ?? {};
    const { setValue, watch } = formInstance ?? {};

    const { allPayers } = useAllPayers();

    const [showPlans, setShowPlans] = useState(
        (formData?.serviceCounties?.length ?? 0) > 0 &&
            (Object.keys(formData?.contractedPayerIds ?? {}).length ??
                formData?.preExistingPayerIds?.length ??
                0) > 0, //if they already picked some, have it shown already
    );

    const [showPreExistingSection, setShowPreExistingSection] = useState(
        (formData?.preExistingPayerIds?.length ?? 0) > 0,
    );

    const selectedPayerIds: Record<string, boolean> = watch?.(
        'contractedPayerIds',
        formData?.contractedPayerIds ?? {},
    );

    const setSelectedPayerIds = useCallback(
        (newVal: Record<string, boolean>) => {
            setValue?.('contractedPayerIds', newVal, { shouldDirty: true });
        },
        [setValue],
    );

    const counties: string[] = watch?.(
        'serviceCounties',
        formData?.serviceCounties ?? [],
    );

    const setCounties = useCallback(
        (newVal: string[]) => {
            if (Object.keys(allPayers).length === 0) return;

            setValue?.('serviceCounties', newVal, { shouldDirty: true });

            const updatedSelectedPayerIds: Record<string, boolean> = {};

            newVal.forEach((county) => {
                Object.values(allPayers).forEach((payer) => {
                    if (payer.countiesServed.includes(county)) {
                        updatedSelectedPayerIds[payer.externalPayerId] =
                            selectedPayerIds[payer.externalPayerId] ?? true;
                    }
                });
            });

            setSelectedPayerIds(updatedSelectedPayerIds);
        },
        [allPayers, selectedPayerIds, setSelectedPayerIds, setValue],
    );

    const existingPayerIds: string[] = watch?.(
        'preExistingPayerIds',
        formData?.preExistingPayerIds ?? [],
    );

    const setExistingPayerIds = useCallback(
        (newVal: string[]) => {
            setValue?.('preExistingPayerIds', newVal, { shouldDirty: true });
        },
        [setValue],
    );

    const [hasExistingPlans, setHasExistingPlans] = useState<string>(
        existingPayerIds.length > 0 ? 'true' : '',
    );

    const {
        getRadioProps,
        getRootProps,
        setValue: setRadioValueForExistingPlans,
    } = useRadioGroup({
        defaultValue: hasExistingPlans,
        onChangeString: setHasExistingPlans,
    });

    const onClickCountyInSearch = (county: string) => {
        setCounties([...counties, county]);
    };

    const deselectCounty = (county: string) => () => {
        setCounties(counties.filter((c) => c !== county));
    };

    const deselectPreExistingPayer = (payerId: string) => () => {
        setExistingPayerIds(existingPayerIds.filter((id) => id !== payerId));
    };

    const onClickPayerInSearch = (payerId: string) => {
        const payer = allPayers[payerId];

        if (!payer) return;

        const newExistingPayerIds = existingPayerIds.includes(
            payer.externalPayerId,
        )
            ? existingPayerIds
            : [...existingPayerIds, payer.externalPayerId];

        setExistingPayerIds(newExistingPayerIds);
    };

    const planPossibilities: AutoCompletePossibility[] = useMemo(() => {
        return Object.values(allPayers).map((payer) => {
            return {
                label: payer.name,
                value: payer.externalPayerId,
                disabled: existingPayerIds.includes(payer.externalPayerId),
            };
        });
    }, [existingPayerIds, allPayers]);

    const countiesPossibilities: AutoCompletePossibility[] = useMemo(() => {
        return COUNTIES.map((county) => {
            return {
                label: county,
                value: county,
                disabled: counties.includes(county),
            };
        });
    }, [counties]);

    const [hasAccountedForLoadedToggling, setHasAccountedForLoadedToggling] =
        useState<boolean>(false);

    useEffect(
        function accountForLoadedToggling() {
            if (!formData || hasAccountedForLoadedToggling) return;

            setHasAccountedForLoadedToggling(true);

            const loadedShowPlans =
                (formData?.serviceCounties?.length ?? 0) > 0 &&
                (Object.keys(formData?.contractedPayerIds ?? {}).length ??
                    formData?.preExistingPayerIds?.length ??
                    0) > 0;
            const loadedShowPreExistingSections =
                (formData?.preExistingPayerIds?.length ?? 0) > 0;

            if (loadedShowPlans !== showPlans) {
                setShowPlans(loadedShowPlans);
            }

            if (loadedShowPreExistingSections !== showPreExistingSection) {
                setShowPreExistingSection(loadedShowPreExistingSections);
            }

            if (
                loadedShowPreExistingSections !==
                (hasExistingPlans === 'true')
            ) {
                setHasExistingPlans('true');
                setRadioValueForExistingPlans('true');
            }
        },
        [
            formData,
            hasAccountedForLoadedToggling,
            hasExistingPlans,
            setRadioValueForExistingPlans,
            showPlans,
            showPreExistingSection,
        ],
    );

    return (
        <Flex
            {...commonStyling}
            height={Zyg('100vh', '100%')}
            aria-label="service areas and plans form"
        >
            <FlexCol gap={PX.SPACING.PX.XXL} className="internalWrapper">
                <FlexCol
                    className="countySearchComponent"
                    position="relative"
                    gap={PX.SPACING.REM.S}
                >
                    <FONTS.H2>Select the counties you serve</FONTS.H2>
                    <AutoComplete
                        id="counties"
                        searchIconOnRight={true}
                        subLabel="Find the counties you serve from the look-up above. "
                        possibilities={countiesPossibilities}
                        onClickItem={onClickCountyInSearch}
                        noResultsHeader="All Counties"
                    />
                    <FlexRow gap={PX.SPACING.REM.M} wrap="wrap">
                        {counties.map((county, idx) => (
                            <TextTag
                                key={`county_tag_${idx}`}
                                text={county}
                                onClickClose={deselectCounty(county)}
                            />
                        ))}
                    </FlexRow>
                    {!showPlans && (
                        <PrimaryButton
                            disabled={counties.length === 0}
                            onClick={() => {
                                if (counties.length !== 0) {
                                    setShowPlans(true);
                                }
                            }}
                        >
                            Next
                        </PrimaryButton>
                    )}
                </FlexCol>

                {showPlans && counties.length > 0 && (
                    <FlexCol gap={PX.SPACING.REM.XL}>
                        <MCPTable
                            counties={counties}
                            selectedPayerIds={selectedPayerIds}
                            setSelectedPayerIds={setSelectedPayerIds}
                            allPayers={allPayers}
                            isLoading={
                                Object.keys(allPayers ?? {}).length === 0
                            }
                        />

                        {showPreExistingSection ||
                        (existingPayerIds?.length ?? 0) > 0 ? (
                            <FlexCol gap={PX.SPACING.PX.L}>
                                <FlexCol gap={PX.SPACING.PX.M}>
                                    <FONTS.H2>
                                        Do you have any existing Managed Care
                                        Plan contracts?
                                    </FONTS.H2>
                                    <FONTS.P1>
                                        We are collecting which Managed Care
                                        Plans you already have contracts with
                                        for two reasons:
                                    </FONTS.P1>
                                    <FONTS.P1>
                                        1) If you're already contracted with a
                                        plan, we may be able to speed up your
                                        credentialing process.
                                    </FONTS.P1>
                                    <FONTS.P1>
                                        2) We will need to double check that
                                        your individual contract will not be
                                        canceled if you get credentialed through
                                        Loula.
                                    </FONTS.P1>
                                </FlexCol>
                                <FlexCol
                                    {...getRootProps()}
                                    gap={PX.SPACING.PX.M}
                                >
                                    <Radio
                                        {...getRadioProps({ value: 'true' })}
                                        value="true"
                                    >
                                        <FONTS.P1>
                                            Yes, I have at least one existing
                                            contract with a Managed Care Plan .
                                        </FONTS.P1>
                                    </Radio>
                                    <Radio
                                        {...getRadioProps({ value: 'false' })}
                                        value="false"
                                    >
                                        <FONTS.P1>
                                            No, I do not have any existing
                                            contracts
                                        </FONTS.P1>
                                    </Radio>
                                </FlexCol>

                                {hasExistingPlans === 'true' && (
                                    <>
                                        <AutoComplete
                                            id="existingPlans"
                                            label="Select all existing Managed Care Plan contracts"
                                            subLabel="Search for the Managed Care Plans you have contracted with from the look-up above. "
                                            possibilities={planPossibilities}
                                            onClickItem={onClickPayerInSearch}
                                            noResultsHeader="All Plans"
                                        />
                                        <FlexRow
                                            gap={PX.SPACING.REM.M}
                                            wrap="wrap"
                                        >
                                            {existingPayerIds.map(
                                                (payerId, idx) => (
                                                    <TextTag
                                                        key={`text_tag_${payerId}_${idx}`}
                                                        text={
                                                            allPayers[payerId]
                                                                ?.name ?? ''
                                                        }
                                                        onClickClose={deselectPreExistingPayer(
                                                            payerId,
                                                        )}
                                                    />
                                                ),
                                            )}
                                        </FlexRow>
                                    </>
                                )}
                            </FlexCol>
                        ) : (
                            <PrimaryButton
                                onClick={() => setShowPreExistingSection(true)}
                            >
                                Next
                            </PrimaryButton>
                        )}
                    </FlexCol>
                )}
            </FlexCol>
        </Flex>
    );
};
