import { Box, Collapse, Flex, FlexProps, Spacer } from '@chakra-ui/react';
import COLORS from 'ui/colors';
import FONTS from 'ui/fonts';
import PX, { SPACING } from 'ui/px';
import { ProgressableStep } from 'onboarding/onboarding.model';
import React, { MouseEventHandler, useContext, useEffect } from 'react';
import SHADOWS from 'ui/shadows';
import { AnimatedStepSwapper } from './AnimatedStepSwapper';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import { PrimaryButton, SecondaryButton } from 'ui/components/Button';
import { OnboardingContext } from 'onboarding/OnboardingContext';
import { AlertDialog } from 'ui/AlertDialogue';
import {
    OnboardingAttestationScreen,
    useOnboardingAttestations,
} from './OnboardingAttestationScreen';
import useScreenSize from 'hooks/useScreenSize';
import { Waves } from 'ui/animations/gradients';
import { PAGE_COLLAPSE_DURATION } from 'models/constants/animationConstants';
import { ICONS } from 'ui/icons';

export const StepWrapperStyling = {
    background: COLORS.UTIL.Gray.SHEET,
    borderRadius: PX.RADII.LG,
    boxShadow: SHADOWS.sheetElevation,
};

export const MobileStepWrapper = ({
    setShowHelpTag,
}: {
    setShowHelpTag: (_show: boolean) => void;
}): React.ReactElement => {
    const { isShortScreen } = useScreenSize();

    const onboardingContext = useContext(OnboardingContext);

    const {
        steps,
        activeStepIdx,
        setIsCollapsed,
        activeSubStepIdx,
        isCollapsed,
    } = onboardingContext ?? {};

    const isMenuOpen = !isCollapsed;
    const setMenuIsOpen = (val: boolean) => setIsCollapsed?.(!val);

    const showContent = !isMenuOpen || (isMenuOpen && !isShortScreen);

    useEffect(
        function showHelpTagEffect() {
            setShowHelpTag(!showContent);
        },
        [setShowHelpTag, showContent],
    );
    //This has three possible states
    // 1. Menu is closed, so all three parts are shown, stretching to fill vertically
    // 2. Menu is open and screen is short, so only the stepheader is visible
    // 3. Menu is open and screen is tall, so content is goes up to bottom of menu, shortens to fit

    return (
        <Flex
            className="mobileStepWrapper"
            role="region"
            aria-label="active step"
            width="100%"
            marginTop="auto"
            direction="column"
            justifyContent="space-between"
            overflowY="hidden"
            zIndex={2}
            {...StepWrapperStyling}
            boxShadow="-5px 0px 10px 3px rgba(208, 196, 191, 0.30)"
        >
            <StepHeader
                isMobile={true}
                onClick={() => {
                    isMenuOpen && isShortScreen && setMenuIsOpen(false);
                }}
                stepTitle={
                    steps && activeStepIdx !== undefined
                        ? steps[activeStepIdx]?.title
                        : ''
                }
                subSteps={
                    steps && activeStepIdx ? steps[activeStepIdx]?.subSteps : []
                }
                activeSubStepIdx={activeSubStepIdx}
            />

            <Collapse
                in={showContent}
                startingHeight={1}
                transition={{
                    enter: { duration: PAGE_COLLAPSE_DURATION / 2 },
                    exit: { duration: PAGE_COLLAPSE_DURATION / 2 },
                }}
                style={{}} //issue2: without flex:1, this doesnt stretch vertically
            >
                <Box
                    height={`calc(100% - 64px)`}
                    overflowX="hidden"
                    className="stepComponentWrapper"
                >
                    <AnimatedStepSwapper />
                </Box>
                <StepFooter />
            </Collapse>
        </Flex>
    );
};

export const DesktopStepWrapper = ({
    isLoading = false,
}: {
    isLoading?: boolean;
}): React.ReactElement => {
    const onboardingContext = useContext(OnboardingContext);

    const { steps, activeStepIdx, activeSubStepIdx } = onboardingContext ?? {};

    return (
        <Flex
            className="desktopStepWrapper"
            role="region"
            aria-label="active step"
            height="100%"
            flex={1}
            direction="column"
            overflowX="hidden"
            overflowY="hidden"
            justifyContent="space-between"
            {...StepWrapperStyling}
            {...(isLoading
                ? Waves({
                      sp: COLORS.UTIL.Gray,
                      base: 100,
                      intensity: 3,
                  })
                : {})}
        >
            <StepHeader
                transition="all 0.5s"
                opacity={isLoading ? 0 : 1}
                isMobile={false}
                stepTitle={
                    steps && activeStepIdx !== undefined
                        ? steps[activeStepIdx]?.title
                        : ''
                }
                subSteps={
                    steps && activeStepIdx ? steps[activeStepIdx]?.subSteps : []
                }
                activeSubStepIdx={activeSubStepIdx}
            />

            <AnimatedStepSwapper
                transition="all 0.5s"
                opacity={isLoading ? 0 : 1}
            />

            <StepFooter transition="all 0.5s" opacity={isLoading ? 0 : 1} />
        </Flex>
    );
};

export const StepHeader = ({
    stepTitle,
    onClick,
    subSteps,
    activeSubStepIdx,
    isMobile = false,
    ...props
}: FlexProps & {
    stepTitle: string;
    onClick?: MouseEventHandler;
    subSteps?: ProgressableStep[];
    activeSubStepIdx?: number;
    isMobile?: boolean;
}) => {
    const onboardingContext = useContext(OnboardingContext);

    const { activeStepIdx, goToSubStep } = onboardingContext ?? {};

    const stepTitleToDisplay = !isMobile
        ? stepTitle
        : (subSteps?.length ?? 0) === 0
          ? stepTitle
          : (subSteps?.[activeSubStepIdx ?? 0]?.title ?? stepTitle);

    return (
        <FlexCol
            role="region"
            aria-labelledby="stepTitle"
            className="stepHeader"
            paddingY={isMobile ? SPACING.PX.M : SPACING.PX.XXL}
            paddingX={isMobile ? SPACING.PX.M : SPACING.PX.L}
            onClick={onClick}
            gap={PX.SPACING.REM.M}
            align="start"
            css={{ WebkitTapHighlightColor: 'transparent' }}
            {...props}
        >
            {!subSteps?.length && (
                <FONTS.H3 id="stepTitle" color={COLORS.PRIMARY.Grey}>
                    {stepTitleToDisplay}
                </FONTS.H3>
            )}
            {subSteps && (
                <FlexRow
                    role="region"
                    aria-labelledby="activeSubStepTitle"
                    wrap="wrap"
                    gap={PX.SPACING.PX.S}
                >
                    {subSteps.map((subStep, idx) => (
                        <FlexRow
                            key={`subStep_${idx}`}
                            gap={PX.SPACING.PX.S}
                            align="center"
                        >
                            {idx > 0 && (
                                <ICONS.ArrowForwardIOS
                                    size={16}
                                    color={COLORS.UTIL.Gray[500]}
                                />
                            )}
                            <FONTS.P1
                                key={idx}
                                color={
                                    activeSubStepIdx === idx
                                        ? COLORS.PRIMARY.Warning
                                        : COLORS.PRIMARY.Grey
                                }
                                fontWeight={
                                    activeSubStepIdx === idx ? 600 : 400
                                }
                                id={
                                    activeSubStepIdx === idx
                                        ? 'activeSubStepTitle'
                                        : undefined
                                }
                                cursor="pointer"
                                onClick={goToSubStep?.(activeStepIdx ?? 0, idx)}
                            >
                                {subStep.title}
                            </FONTS.P1>
                        </FlexRow>
                    ))}
                </FlexRow>
            )}
        </FlexCol>
    );
};

const StepFooter = ({ ...props }: FlexProps): React.ReactElement => {
    const onboardingContext = useContext(OnboardingContext);

    const {
        goToNextStep,
        goToPreviousStep,
        isFirstStep,
        isLastStep,
        areAllStepsComplete,
        submitForm,
    } = onboardingContext ?? {};

    const {
        showAttestations,
        setShowAttestations,
        canSubmitAttestations,
        setCanSubmitAttestations,
    } = useOnboardingAttestations();

    return (
        <Flex
            role="region"
            aria-label="navigation buttons"
            className="StepFooter"
            justifyContent="space-between"
            borderTop={`1px solid ${COLORS.UTIL.Gray[500]}`}
            paddingY={SPACING.PX.M}
            paddingX={SPACING.PX.L}
            background={COLORS.UTIL.Gray.WARM}
            {...props}
        >
            <SecondaryButton
                opacity={isFirstStep ? 0 : 1}
                transition="all 0.3s"
                onClick={goToPreviousStep}
                aria-label="go to previous step"
                disabled={isFirstStep}
            >
                Back
            </SecondaryButton>

            <Spacer />

            {!isLastStep && (
                <PrimaryButton
                    aria-label="save and continue"
                    onClick={goToNextStep}
                >
                    Next
                </PrimaryButton>
            )}

            {isLastStep && (
                <PrimaryButton
                    aria-label="submit onboarding"
                    disabled={!areAllStepsComplete}
                    onClick={() => {
                        if (areAllStepsComplete) setShowAttestations(true);
                    }}
                >
                    Submit
                </PrimaryButton>
            )}

            {showAttestations && (
                <AlertDialog
                    title="To submit your application, please attest to the following statements."
                    leastDestructiveBtnText="Cancel"
                    destructiveBtnText="Accept and submit"
                    onClickDestructive={() => {
                        if (canSubmitAttestations) submitForm?.();
                        setShowAttestations(false);
                    }}
                    isDestructiveEnabled={canSubmitAttestations}
                    onClickLeastDestructive={() => setShowAttestations(false)}
                    minWidth="600px"
                    width="400px"
                    maxWidth="100%"
                >
                    <OnboardingAttestationScreen
                        onAttestationsUpdate={(isComplete) =>
                            setCanSubmitAttestations(isComplete)
                        }
                    />
                </AlertDialog>
            )}
        </Flex>
    );
};
