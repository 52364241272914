import useScreenSize from 'hooks/useScreenSize';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import COLORS from 'ui/colors';
import FONTS from 'ui/fonts';
import { ICONS } from 'ui/icons';
import PX, { PXSTR } from 'ui/px';

export const NavBarButton = ({
    icon,
    label,
    link,
    isActive,
}: {
    icon?: keyof typeof ICONS;
    label: string;
    link: string;
    isActive?: boolean;
}) => {
    const ICON = !!icon ? ICONS[icon] : null;

    const focusStyles = {
        background: COLORS.BRAND.Periwinkle[200],
        outline: 'none',
        color: COLORS.PRIMARY.Blue,
    };

    const activeStyle = {
        fontWeight: 600,
    };

    const regularStyles = {
        color: COLORS.UTIL.Gray[800],
        outline: 'none',
    };

    const [isFocused, setIsFocused] = useState<boolean>(false);

    const { isNarrowScreen } = useScreenSize();

    return (
        <Link
            style={{
                transition: 'all 0.3s',
                flex: 1,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                borderRadius: PX.RADII.ROUNDED,
                padding: `${isNarrowScreen ? PXSTR.SM : PXSTR.S} ${isNarrowScreen ? PXSTR.L : PXSTR.M}`,
                WebkitTapHighlightColor: 'transparent',
                gap: PXSTR.S,
                ...(isFocused || isActive ? focusStyles : regularStyles),
                ...(isActive ? activeStyle : {}),
            }}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onMouseEnter={() => setIsFocused(true)}
            onMouseLeave={() => setIsFocused(false)}
            id={`navBar_${label}`}
            to={{ pathname: link }}
            replace={true}
        >
            {ICON && <ICON size={isNarrowScreen ? 18 : 16} />}
            <FONTS.P1 minWidth="100px">{label}</FONTS.P1>
        </Link>
    );
};
