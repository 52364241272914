import { Collapse, Flex } from '@chakra-ui/react';
import COLORS from 'ui/colors';
import PX from 'ui/px';
import { ProgressIndicator } from '../components/ProgressIndicator';

import React, { useContext } from 'react';

import { UserTag } from '../components/UserTag';
import { NumCompleteTracker } from '../components/NumCompleteTracker';
import { nameToShow } from 'util/Utils';
import { IconButton } from 'ui/components/IconButton';
import { OnboardingContext } from '../OnboardingContext';
import { Fade } from '@chakra-ui/react';
import { HelpTag } from './HelpTag';
import { Waves } from 'ui/animations/gradients';
import { COLLAPSE_DURATION } from 'models/constants';

type SideBarComponentProps = {
    showHelpTag?: boolean;
    isLoading?: boolean;
};

export const MobileNavBar = ({
    showHelpTag,
}: SideBarComponentProps): React.ReactElement => {
    const onboardingState = useContext(OnboardingContext);

    const { steps, numComplete, isCollapsed, setIsCollapsed, formData } =
        onboardingState ?? {};

    return (
        <Flex
            direction="column"
            width="100%"
            //flex={isCollapsed ? undefined : 1}
            className="mobileSidebar"
            role="region"
            aria-label="sidebar"
        >
            <Flex
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                padding={`${PX.SPACING.PX.S} ${PX.SPACING.PX.S} ${PX.SPACING.PX.M} ${PX.SPACING.PX.S}`}
            >
                <IconButton
                    paddingX={PX.SPACING.PX.S}
                    aria-label="open menu"
                    icon={isCollapsed ? 'Menu' : 'Close'}
                    color={COLORS.PRIMARY.Blue}
                    onClick={() => setIsCollapsed?.(!isCollapsed)}
                />
                <NumCompleteTracker
                    numComplete={numComplete ?? 0}
                    numTotal={(steps?.length ?? 0) - 1}
                />
            </Flex>
            <Collapse
                in={!isCollapsed}
                startingHeight={1}
                transition={{
                    enter: { duration: COLLAPSE_DURATION / 2 },
                    exit: { duration: COLLAPSE_DURATION / 2 },
                }}
                style={{ overflow: 'visible' }} //So we get the benefit of size changing, but not doing awk slide
            >
                <Flex
                    //flex={1}
                    paddingX={[PX.SPACING.REM.S, PX.SPACING.REM.L]}
                    paddingBottom={0}
                    className="progressInformation"
                    direction="column"
                    gap={PX.SPACING.PX.M}
                >
                    <UserTag
                        overrideName={nameToShow(
                            formData?.firstName,
                            formData?.lastName,
                            formData?.preferredName,
                        )}
                    />
                    <ProgressIndicator isMobile={true} />
                    <Fade in={showHelpTag}>
                        <HelpTag />
                    </Fade>
                </Flex>
            </Collapse>
        </Flex>
    );
};

export const DesktopSideBar = ({
    isLoading = false,
}: SideBarComponentProps): React.ReactElement => {
    const onboardingContext = useContext(OnboardingContext);

    const { numComplete, steps, formData } = onboardingContext ?? {};

    return (
        <Flex
            role="region"
            aria-label="sidebar"
            direction="column"
            height="100%"
            className="sidebar"
            background={COLORS.BRAND.Periwinkle[100]}
            transition="all 0.3s"
            {...(isLoading
                ? Waves({
                      sp: COLORS.BRAND.Periwinkle,
                      base: 0,
                      intensity: 2,
                  })
                : {})}
            padding={`${PX.SPACING.REM.XL} ${PX.SPACING.REM.XL} ${PX.SPACING.REM.M} ${PX.SPACING.REM.L}`}
        >
            <Flex direction="column" overflowY="scroll">
                <Flex
                    className="progressInformation"
                    direction="column"
                    gap={PX.SPACING.REM.XL}
                >
                    <NumCompleteTracker
                        numComplete={numComplete ?? 0}
                        numTotal={(steps?.length ?? 0) - 1}
                        isLoading={isLoading}
                    />

                    <ProgressIndicator isMobile={false} isLoading={isLoading} />
                </Flex>
                <HelpTag opacity={isLoading ? 0 : 1} transition="all 0.3s" />
            </Flex>
            <UserTag
                isLoading={isLoading}
                overrideName={nameToShow(
                    formData?.firstName,
                    formData?.lastName,
                    formData?.preferredName,
                )}
            />
        </Flex>
    );
};
