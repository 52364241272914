import { FlexProps } from '@chakra-ui/react';
import { ResumeItem } from 'models/schemas';
import { useMemo } from 'react';
import COLORS from 'ui/colors';
import { IconButton } from 'ui/components/IconButton';
import FONTS from 'ui/fonts';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import PX from 'ui/px';
import { differenceInMonths, formatDate, formatDistance } from 'date-fns';

export const ResumeItemCard = ({
    item,
    onClickEdit,
    onClickDelete,
    ...props
}: FlexProps & {
    item: ResumeItem;
    onClickEdit?: () => void;
    onClickDelete?: () => void;
}) => {
    const { startDate, endDate, distance } = useMemo(() => {
        const startObj = new Date(item.startDate);
        const endObj = item.current ? new Date() : new Date(item.endDate);
        const distance =
            differenceInMonths(endObj, startObj) < 1
                ? '< 1 month'
                : formatDistance(endObj, startObj);
        const startDateStr = formatDate(startObj, 'MMM yyyy');
        const endDateStr = item.current
            ? 'Present'
            : formatDate(endObj, 'MMM yyyy');

        return {
            startDate: startDateStr,
            endDate: endDateStr,
            distance,
        };
    }, [item]);

    return (
        <FlexRow
            width="100%"
            justify="space-between"
            padding={PX.SPACING.REM.XL}
            borderRadius={PX.RADII.MD}
            border={`1px solid ${COLORS.UTIL.Gray[500]}`}
            background={COLORS.PRIMARY.White}
            {...props}
        >
            <FlexCol width="100%" gap={PX.SPACING.PX.M}>
                <FONTS.H3>{item.title}</FONTS.H3>
                <FONTS.P1 fontWeight={600}>{item.company}</FONTS.P1>
                <FONTS.P1>{item.location}</FONTS.P1>
                <FlexRow gap={PX.SPACING.REM.M}>
                    <FONTS.P1>
                        {startDate} - {endDate}
                    </FONTS.P1>
                    <FONTS.P1 color={COLORS.PRIMARY.Grey}>{distance}</FONTS.P1>
                </FlexRow>
                <FONTS.P1 maxWidth="100%" overflowWrap="break-word">
                    {item.description}
                </FONTS.P1>
            </FlexCol>

            <FlexCol gap={PX.SPACING.PX.M}>
                {onClickDelete && (
                    <IconButton
                        aria-label="Edit"
                        icon="Edit"
                        onClick={onClickEdit}
                        color={COLORS.BRAND.Periwinkle[800]}
                        hoverColor={COLORS.BRAND.Periwinkle[700]}
                        activeColor={COLORS.BRAND.Periwinkle[700]}
                    />
                )}

                {onClickDelete && (
                    <IconButton
                        aria-label="Delete"
                        icon="Delete"
                        onClick={onClickDelete}
                        color={COLORS.BRAND.Periwinkle[800]}
                        hoverColor={COLORS.BRAND.Periwinkle[700]}
                        activeColor={COLORS.BRAND.Periwinkle[700]}
                    />
                )}
            </FlexCol>
        </FlexRow>
    );
};
