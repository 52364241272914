import { Text, TextProps } from '@chakra-ui/react';

import PX from './px';
import COLORS from './colors';

/**
 * The singleton font object, has subcomponents for
 * the whole of our design system
 * @example
 * <FONTS.H1>Heading 1 Goes Here</FONTS.H1>
 * <FONTS.DOC>Documentation</FONTS.DOC>
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FONTS = ({ children }: any) => {
    return <div>{children}</div>;
};

const BaseDocumentation = (props: TextProps): React.ReactElement => (
    <Text
        {...props}
        color={props.color ?? COLORS.UTIL.Gray[900]}
        fontFamily="DM Sans" //TODO: Add DM Mono font once I figure out the right font system for prod
    >
        {props.children}
    </Text>
);

const BaseHeading = (props: TextProps): React.ReactElement => (
    <Text
        {...props}
        color={props.color ?? COLORS.UTIL.Gray[900]}
        font="DM Sans"
        fontWeight="semibold"
    >
        {props.children}
    </Text>
);

const BaseParagraph = (props: TextProps): React.ReactElement => (
    <Text
        {...props}
        color={props.color ?? COLORS.UTIL.Gray[900]}
        font="DM Sans"
    >
        {props.children}
    </Text>
);

FONTS.TITLE = (props: TextProps): React.ReactElement => (
    <Text
        as="h1"
        font="DM Sans"
        fontWeight={700}
        fontSize={PX.SPACING.REM.XXL}
        lineHeight={1.1}
        color={props.color ?? COLORS.UTIL.Gray[900]}
        {...props}
    >
        {props.children}
    </Text>
);

FONTS.H1 = (props: TextProps): React.ReactElement => (
    <BaseHeading
        as="h1"
        {...props}
        fontSize={PX.SPACING.REM.XL}
        lineHeight={1.2}
    >
        {props.children}
    </BaseHeading>
);

FONTS.H2 = (props: TextProps): React.ReactElement => (
    <BaseHeading
        as="h2"
        {...props}
        fontSize={PX.SPACING.REM.L}
        lineHeight={1.2}
    >
        {props.children}
    </BaseHeading>
);

FONTS.H3 = (props: TextProps): React.ReactElement => (
    <BaseHeading
        as="h3"
        {...props}
        fontSize={PX.SPACING.REM.M}
        lineHeight={1.2}
    >
        {props.children}
    </BaseHeading>
);

FONTS.H4 = (props: TextProps): React.ReactElement => (
    <BaseHeading
        as="h4"
        {...props}
        fontSize={PX.SPACING.REM.SM}
        lineHeight={1.2}
    >
        {props.children}
    </BaseHeading>
);

FONTS.H5 = (props: TextProps): React.ReactElement => (
    <BaseHeading
        as="h5"
        {...props}
        fontSize={PX.SPACING.REM.S}
        lineHeight={1.2}
    >
        {props.children}
    </BaseHeading>
);

FONTS.H6 = (props: TextProps): React.ReactElement => (
    <BaseHeading
        as="h6"
        {...props}
        textTransform="uppercase"
        fontSize={PX.SPACING.REM.S}
        lineHeight={1.2}
        letterSpacing={0.7}
    >
        {props.children}
    </BaseHeading>
);

FONTS.P1 = (props: TextProps): React.ReactElement => (
    <BaseParagraph {...props} fontSize={PX.SPACING.REM.SM} lineHeight={1.5}>
        {props.children}
    </BaseParagraph>
);

FONTS.P2 = (props: TextProps): React.ReactElement => (
    <BaseParagraph {...props} fontSize={PX.SPACING.REM.S} lineHeight={1.29}>
        {props.children}
    </BaseParagraph>
);

FONTS.CAPTION = (props: TextProps): React.ReactElement => (
    <BaseParagraph {...props} fontSize={PX.SPACING.REM.XS}>
        {props.children}
    </BaseParagraph>
);

FONTS.TAG = (props: TextProps): React.ReactElement => (
    <BaseHeading
        {...props}
        textTransform="uppercase"
        fontSize={PX.SPACING.REM.XS}
    >
        {props.children}
    </BaseHeading>
);

FONTS.DOC = (props: TextProps): React.ReactElement => (
    <BaseDocumentation {...props} fontSize={PX.SPACING.REM.SM}>
        {props.children}
    </BaseDocumentation>
);

export default FONTS;

export const FontsForStory = ({
    fontType,
    testText,
}: TextProps & { fontType: keyof typeof FONTS; testText: string }) => {
    const FontComponent = FONTS[fontType];

    if (!FontComponent) return null;

    return <FontComponent>{testText}</FontComponent>;
};
