import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { authorize } from '../api/API';
import Loading from './Loading';
import { boot } from '@intercom/messenger-js-sdk';
import { PractitionerReturnType } from 'models/schemas';

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dataLayer: any[];
    }
}

//TODO-Mina: I dont like this setup, rebuild
const PrivateRoute = () => {
    const { isAuthenticated, loginWithRedirect, getAccessTokenSilently, user } =
        useAuth0();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [provider, setProvider] = useState<PractitionerReturnType | null>(
        null,
    );

    const checkEmailVerified = useCallback(async (): Promise<boolean> => {
        if (!isAuthenticated) return false;
        return new Promise((resolve, reject) => {
            if (user && !user.email_verified) {
                navigate('/verify-email');
                reject('verification required');
            }

            resolve(true);
        });
    }, [isAuthenticated, navigate, user]);

    const bootIntercom = useCallback(() => {
        boot({
            app_id: 'ocd9l3z9',
            email: user?.email,
            user_id: user?.id,
        });
    }, [user?.email, user?.id]);

    const getProfileFn = useCallback(
        (token: string): Promise<PractitionerReturnType> =>
            authorize(token)
                .get(`/providers/me`)
                .then((res) => {
                    setProvider(res.data);
                    return res.data;
                }),
        [],
    );

    const checkOnboardingStatus = useCallback(async () => {
        const token = await getAccessTokenSilently();
        const providerProfile = await getProfileFn(token);

        //TODO: what's this for? pre-existing code I moved into hooks
        // set datalayer variables
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            email: providerProfile?.user.email,
        });
        window.dataLayer.push({
            event: 'userConfigured',
        });
    }, [getAccessTokenSilently, getProfileFn]);

    useEffect(() => {
        if (isAuthenticated) {
            checkEmailVerified()
                .then(bootIntercom)
                .then(checkOnboardingStatus)
                .then(() => setLoading(false))
                .catch((err) => {
                    // eslint-disable-next-line no-console
                    console.error(err);
                    if (err !== 'verification required') {
                        navigate('/error');
                    }
                });
        } else {
            loginWithRedirect();
        }
    }, [
        bootIntercom,
        checkEmailVerified,
        checkOnboardingStatus,
        isAuthenticated,
        loginWithRedirect,
        navigate,
    ]);

    if (!loading) return <Outlet context={provider} />;

    return <Loading />;
};

export default PrivateRoute;

export function useProvider() {
    return useOutletContext<PractitionerReturnType | null>();
}
