import { Box, Image, Slide, SlideFade } from '@chakra-ui/react';
import useScreenSize from 'hooks/useScreenSize';
import {
    LANDING_GRAPHIC_ENTRANCE_DURATION,
    LANDING_TEXT_ENTRANCE_DURATION,
} from 'models/constants/animationConstants';
import COLORS from 'ui/colors';
import FONTS from 'ui/fonts';
import PX from 'ui/px';
import { Zyg } from 'util/Utils';

//TODO-Mina: Apply the noise layer that ray has in designs
export const LandingGraphic = () => {
    const { isNarrowScreen } = useScreenSize();

    return (
        <Box
            className="landingGraphicInnerContainer"
            position="relative"
            width={Zyg('100%', '50%')}
            transition="all 0.3s"
            height={isNarrowScreen ? '100vh' : '100%'}
        >
            <SlideFade
                style={{ width: '100%', height: '100%' }}
                in={true}
                offsetY={-PX.NUM.PX.XXL}
                transition={{
                    exit: { duration: LANDING_TEXT_ENTRANCE_DURATION },
                    enter: { duration: LANDING_GRAPHIC_ENTRANCE_DURATION },
                }}
            >
                <Box
                    width="100%"
                    height="100%"
                    className="graphicMask"
                    bgImage="url('/images/welcome.png')"
                    css={{
                        maskImage: "url('/masks/halfPill.png')",
                        maskSize: '100% 100%',
                        maskRepeat: 'no-repeat',
                    }}
                >
                    <Box
                        position="relative"
                        width="100%"
                        height="100%"
                        background={`linear-gradient(180deg, #FEE9DC 0%, #F6CCB3 100%)`}
                    >
                        <SlideFade
                            style={{
                                margin: 'auto',
                                width: 'fit-content',
                                maxWidth: '260px',
                                textAlign: 'center',
                                zIndex: 2,
                            }}
                            in={true}
                            offsetY={PX.NUM.PX.XXL}
                            transition={{
                                exit: { delay: 1 },
                                enter: {
                                    duration: LANDING_GRAPHIC_ENTRANCE_DURATION,
                                },
                            }}
                        >
                            <FONTS.TITLE
                                paddingTop={[0, null, null, null, '60%']}
                                color={COLORS.PRIMARY.Warning}
                            >
                                Welcome to Loula.
                            </FONTS.TITLE>
                        </SlideFade>

                        <Slide
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                zIndex: 1,
                            }}
                            in={true}
                            direction="bottom"
                            transition={{
                                enter: { delay: 0.5, duration: 2.5 },
                            }}
                        >
                            <Box
                                width="100%"
                                marginX="auto"
                                maxW={['40%', null, null, null, '360px']}
                            >
                                <Image
                                    objectFit="contain"
                                    src={'/graphics/family.png'}
                                    alt={'Birthing group'}
                                />
                            </Box>
                        </Slide>

                        <Slide
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                maxHeight: '200px',
                            }}
                            in={true}
                            direction="bottom"
                            transition={{
                                enter: { duration: 2 },
                            }}
                        >
                            <Box
                                maxW={['100%', null, null, null, '100%']}
                                width="100%"
                            >
                                <Image
                                    objectFit="contain"
                                    src={'/graphics/hill.png'}
                                    alt={'Hill'}
                                />
                            </Box>
                        </Slide>
                    </Box>
                </Box>
            </SlideFade>
        </Box>
    );
};
