import { OnboardingFormDataModel } from 'models/schemas';
import { ProgressableStep, StepsMetadata } from 'onboarding/onboarding.model';

import { DocumentationStep } from 'onboarding/components/steps/DocumentationStep';
import { InfoStep } from 'onboarding/components/steps/InfoStep';
import { WorkHistoryStep } from 'onboarding/components/steps/WorkHistoryStep';
import { IdentityStep } from 'onboarding/components/steps/IdentityStep';
import { ReviewStep } from 'onboarding/components/steps/ReviewStep';
import { BusinessInfoStep } from 'onboarding/components/steps/BusinessInfo';
import { ServiceAreaStep } from 'onboarding/components/steps/ServiceAreaStep';
import { DocumentationSubstepOne } from 'onboarding/components/steps/DocumentationSubstepOne';
import { DocumentationSubstepTwo } from 'onboarding/components/steps/DocumentationSubstepTwo';
import { DocumentationSubstepThree } from 'onboarding/components/steps/DocumentationSubstepThree';
import { StringBoolMapHasAtleastOne } from 'util/Utils';
import { ProofOfTrainingPathway } from 'models/constants';

export const STEPS_METADATA: StepsMetadata[] = [
    {
        title: 'Personal Information',
        slug: 'info',
        subStepMetadata: [],
    },
    {
        title: 'Business Information',
        slug: 'businessInfo',
        subStepMetadata: [],
    },
    {
        title: 'Service Areas & Plans',
        slug: 'plans',
        subStepMetadata: [],
    },
    {
        title: 'Documentation',
        slug: 'documentation',
        subStepMetadata: [
            {
                title: 'Provider Enrollment',
                slug: 'providerEnrollment',
                subStepMetadata: [],
            },
            {
                title: 'Proof of Training',
                slug: 'proofOfTraining',
                subStepMetadata: [],
            },
            {
                title: 'Additional Documentation',
                slug: 'additionalDocuments',
                subStepMetadata: [],
            },
        ],
    },
    {
        title: 'Work History',
        slug: 'workHistory',
        subStepMetadata: [],
    },
    {
        title: 'Verify Your Identity',
        slug: 'identity',
        subStepMetadata: [],
    },
    {
        title: 'Review & Submit',
        slug: 'review',
        subStepMetadata: [],
    },
];

//TODO-Mina: why did I add this stepFields stuff?

export const ALL_STEPS: ProgressableStep[] = [
    {
        ...STEPS_METADATA[0],
        component: InfoStep,
        stepFields: [
            'firstName',
            'middleName',
            'lastName',
            'preferredName',
            'pronouns',
            'dob',
            'npi',
            'tin',
            'phone',
            'personalAddress',
            'languages',
            'race',
            'hasOtherNames',
            'otherNames',
        ],
        customIsStepComplete: (formData: OnboardingFormDataModel | null) => {
            const {
                firstName,
                lastName,
                pronouns,
                dob,
                npi,
                tin,
                phone,
                personalAddress,
                languages,
                race,
                hasOtherNames,
                otherNames,
            } = formData ?? {};

            return (
                !!firstName &&
                !!lastName &&
                (hasOtherNames === false || !!otherNames) &&
                StringBoolMapHasAtleastOne(pronouns) &&
                !!dob &&
                /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d{2}$/.test(
                    dob,
                ) &&
                !!npi &&
                !!tin &&
                tin.toString().length === 11 &&
                !!phone &&
                phone.length === 14 &&
                !!personalAddress?.address1 &&
                Object.values(languages ?? {}).filter((val) => !val).length ===
                    0 &&
                StringBoolMapHasAtleastOne(race)
            );
        },
    },
    {
        ...STEPS_METADATA[1],
        component: BusinessInfoStep,
        stepFields: [
            'businessName',
            'isAddressSameAsPersonal',
            'isNameSameAsPersonal',
            'businessTIN',
            'hasNoBusinessTin',
            'businessAddress',
        ],
        customIsStepComplete: (formData: OnboardingFormDataModel | null) => {
            return (
                (!!formData?.businessAddress?.address1 ||
                    !!formData?.isAddressSameAsPersonal) &&
                (!!formData?.businessName ||
                    !!formData?.isNameSameAsPersonal) &&
                (formData?.hasNoBusinessTin || !!formData?.businessTIN)
            );
        },
    },
    {
        ...STEPS_METADATA[2],
        component: ServiceAreaStep,
        stepFields: [
            'serviceCounties',
            'contractedPayerIds',
            'preExistingPayerIds',
        ],
        customIsStepComplete: (formData: OnboardingFormDataModel | null) => {
            const { serviceCounties, contractedPayerIds, preExistingPayerIds } =
                formData ?? {};
            return (
                (serviceCounties?.length ?? 0) > 0 &&
                (Object.keys(contractedPayerIds ?? {}).length > 0 ||
                    (preExistingPayerIds?.length ?? 0) > 0)
            );
        },
    },
    {
        ...STEPS_METADATA[3],
        component: DocumentationStep,
        stepFields: [],
        subSteps: [
            {
                ...STEPS_METADATA[3].subStepMetadata[0],
                component: DocumentationSubstepOne,
                stepFields: ['mediCalEnrollmentFile', 'cprCertificate'],
            },
            {
                ...STEPS_METADATA[3].subStepMetadata[1],
                component: DocumentationSubstepTwo,
                stepFields: [
                    'proofOfTrainingPathway',
                    'syllabus',
                    'trainingSyllabusMeetsProofReq',
                    'proofOfTraining',
                    'lettersOfRecommendation',
                ],
                customIsStepComplete: (
                    formData: OnboardingFormDataModel | null,
                ) => {
                    const {
                        proofOfTrainingPathway,
                        syllabus,
                        lettersOfRecommendation,
                        trainingSyllabusMeetsProofReq,
                        proofOfTraining,
                    } = formData ?? {};

                    if (!proofOfTrainingPathway) return false;

                    if (proofOfTrainingPathway === ProofOfTrainingPathway.XP) {
                        return !!lettersOfRecommendation?.length;
                    } else if (
                        proofOfTrainingPathway ===
                        ProofOfTrainingPathway.TRAINING
                    ) {
                        return (
                            !!syllabus?.length &&
                            (trainingSyllabusMeetsProofReq ||
                                !!proofOfTraining?.length)
                        );
                    }

                    return false;
                },
            },
            {
                ...STEPS_METADATA[3].subStepMetadata[2],
                component: DocumentationSubstepThree,
                stepFields: [
                    'proofOfHIPAATraining',
                    'proofOfLiabilityInsurance',
                ],
            },
        ],
    },
    {
        ...STEPS_METADATA[4],
        component: WorkHistoryStep,
        stepFields: ['resumeFile', 'resumeItems'],
        customIsStepComplete: (formData: OnboardingFormDataModel | null) => {
            const { resumeFile, resumeItems } = formData ?? {};
            return (
                (resumeFile?.length ?? 0) > 0 || (resumeItems?.length ?? 0) > 0
            );
        },
    },
    {
        ...STEPS_METADATA[5],
        component: IdentityStep,
        stepFields: ['identityVerificationStatus'],
        customIsStepComplete: (formData: OnboardingFormDataModel | null) => {
            const { identityVerificationStatus } = formData ?? {};
            return (
                !!identityVerificationStatus &&
                identityVerificationStatus !== 'needs_input' &&
                identityVerificationStatus !== 'abandoned'
            );
        },
    },
    {
        ...STEPS_METADATA[6],
        component: ReviewStep,
        stepFields: [],
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        customIsStepComplete: (formData: OnboardingFormDataModel | null) => {
            return false;
        },
    },
];
