/* eslint-disable @typescript-eslint/no-explicit-any */
import { KeyboardEventHandler, useRef } from 'react';

/**
 * Useful for components that capture focus at a higher level but want to trigger an input's onclick
 * in one of the children when you press Enter (for keyboard nav)
 * @returns
 * *childInputRef*: use this on the input component, so like <input ref={childInputRef}...
 * *topLevelProps*: the component capturing the tab and key events (usually wherever youre calling this hook from)
 */
export const useChildInputRef = () => {
    const inputRef = useRef(null);

    const handleClick = () => {
        if (inputRef?.current && (inputRef?.current as any)?.click) {
            (inputRef?.current as any)?.click();
        }
    };

    const topLevelOnKeyDown: KeyboardEventHandler = (key) => {
        if (key.key === 'Enter') handleClick();
    };

    return {
        childInputRef: inputRef,
        topLevelProps: {
            onKeyDown: topLevelOnKeyDown,
        },
    };
};
