import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import COLORS from 'ui/colors';
import PX from 'ui/px';

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(tableAnatomy.keys);

//TODO: define the other table style as a variant like this
const minimalist = definePartsStyle(() => {
    // const { colorScheme, colorMode } = props;

    return {
        table: {
            borderCollapse: 'separate',
            borderSpacing: 0,
            borderBottom: `1px solid ${COLORS.UTIL.Gray[500]}`,
            borderBottomRightRadius: PX.RADII.MD,
            borderBottomLeftRadius: PX.RADII.MD,
            textTransform: 'none',
        },
        thead: {
            border: 'none',
            tr: {
                background: COLORS.UTIL.Gray[300],
                color: COLORS.UTIL.Gray[800],
                th: {
                    textTransform: 'none',
                    fontSize: 14,
                    border: `1px solid ${COLORS.UTIL.Gray[500]}`,
                    borderBottom: `1px solid ${COLORS.UTIL.Gray[300]}`,
                    borderX: `1px solid ${COLORS.UTIL.Gray[300]}`,
                },
                'th:first-of-type': {
                    border: `1px solid ${COLORS.UTIL.Gray[500]}`,
                    paddingLeft: PX.SPACING.PX.M,
                    borderBottom: `1px solid ${COLORS.UTIL.Gray[300]}`,
                    borderRight: `1px solid ${COLORS.UTIL.Gray[300]}`,
                    borderTopLeftRadius: PX.RADII.MD,
                },
                'th:last-child': {
                    border: `1px solid ${COLORS.UTIL.Gray[500]}`,
                    borderBottom: `1px solid ${COLORS.UTIL.Gray[300]}`,
                    borderLeft: `1px solid ${COLORS.UTIL.Gray[300]}`,
                    borderTopRightRadius: PX.RADII.MD,
                },
            },
        },
        tbody: {
            tr: {
                td: {
                    padding: PX.SPACING.PX.M,
                },
                'td:first-of-type': {
                    borderLeft: `1px solid ${COLORS.UTIL.Gray[500]}`,
                },
                'td:last-child': {
                    borderRight: `1px solid ${COLORS.UTIL.Gray[500]}`,
                },
                '&:last-of-type': {
                    'td:first-of-type': {
                        borderBottomLeftRadius: PX.RADII.MD,
                        borderLeft: `1px solid ${COLORS.UTIL.Gray[500]}`,
                    },
                    'td:last-child': {
                        borderBottomRightRadius: PX.RADII.MD,
                        borderRight: `1px solid ${COLORS.UTIL.Gray[500]}`,
                    },
                },
            },
        },
        // tr: {
        //     'td:first-of-type': {
        //         borderTopLeftRadius: 'full',
        //         borderBottomLeftRadius: 'full',
        //     },
        //     'td:last-child': {
        //         borderTopRightRadius: 'full',
        //         borderBottomRightRadius: 'full',
        //     },
        // },
        // th: {
        //     '&[data-is-numeric=true]': {
        //         textAlign: 'end',
        //     },
        // },
        // td: {
        //     '&[data-is-numeric=true]': {
        //         textAlign: 'end',
        //     },
        // },
        // caption: {
        //     color: colorMode === 'light' ? `${c}.600` : `${c}.100`,
        // },
        // tbody: {
        //     tr: {
        //         '&:nth-of-type(odd)': {
        //             'th, td': {
        //                 borderBottomWidth: '1px',
        //                 borderColor:
        //                     colorMode === 'light' ? `${c}.100` : `${c}.700`,
        //             },
        //             td: {
        //                 background:
        //                     colorMode === 'light' ? `${c}.100` : `${c}.700`,
        //             },
        //         },
        //         '&:nth-of-type(even)': {
        //             'th, td': {
        //                 borderBottomWidth: '1px',
        //                 borderColor:
        //                     colorMode === 'light' ? `${c}.300` : `${c}.600`,
        //             },
        //             td: {
        //                 background:
        //                     colorMode === 'light' ? `${c}.300` : `${c}.600`,
        //             },
        //         },
        //     },
        // },
        // tfoot: {
        //     tr: {
        //         '&:last-of-type': {
        //             th: { borderBottomWidth: 0 },
        //         },
        //     },
        // },
    };
});

const base = definePartsStyle(() => {
    return {
        table: {
            borderCollapse: 'separate',
            borderSpacing: 0,
            borderBottom: `1px solid ${COLORS.UTIL.Gray[500]}`,
            borderBottomRightRadius: PX.RADII.MD,
            borderBottomLeftRadius: PX.RADII.MD,
            textTransform: 'none',
        },
        thead: {
            border: 'none',
            tr: {
                background: COLORS.UTIL.Gray.WARM,
                color: COLORS.UTIL.Gray[800],
                th: {
                    transition: 'all 0.3s',
                    ':hover': {
                        background: COLORS.UTIL.Gray[300],
                    },
                    ':active': {
                        background: COLORS.UTIL.Gray[400],
                    },
                    cursor: 'pointer',
                    paddingX: PX.SPACING.PX.S,
                    textTransform: 'none',
                    fontSize: 14,
                    border: `1px solid ${COLORS.UTIL.Gray[500]}`,
                    borderBottom: `1px solid ${COLORS.UTIL.Gray[300]}`,
                    borderX: `1px solid ${COLORS.UTIL.Gray.WARM}`,
                },
                'th:first-of-type': {
                    border: `1px solid ${COLORS.UTIL.Gray[500]}`,
                    paddingLeft: PX.SPACING.PX.XL,
                    borderBottom: `1px solid ${COLORS.UTIL.Gray[300]}`,
                    borderRight: `1px solid ${COLORS.UTIL.Gray.WARM}`,
                    borderTopLeftRadius: PX.RADII.MD,
                },
                'th:last-child': {
                    paddingRight: PX.SPACING.PX.XL,
                    border: `1px solid ${COLORS.UTIL.Gray[500]}`,
                    borderBottom: `1px solid ${COLORS.UTIL.Gray[300]}`,
                    borderLeft: `1px solid ${COLORS.UTIL.Gray.WARM}`,
                    borderTopRightRadius: PX.RADII.MD,
                },
            },
        },
        tbody: {
            tr: {
                td: {
                    padding: PX.SPACING.PX.S,
                    borderY: `1px solid ${COLORS.UTIL.Gray[300]}`,
                },
                'td:first-of-type': {
                    borderLeft: `1px solid ${COLORS.UTIL.Gray[500]}`,
                    paddingLeft: PX.SPACING.PX.XL,
                },
                'td:last-child': {
                    borderRight: `1px solid ${COLORS.UTIL.Gray[500]}`,
                },
                '&:last-of-type': {
                    'td:first-of-type': {
                        borderBottomLeftRadius: PX.RADII.MD,
                        borderLeft: `1px solid ${COLORS.UTIL.Gray[500]}`,
                    },
                    'td:last-child': {
                        borderBottomRightRadius: PX.RADII.MD,
                        borderRight: `1px solid ${COLORS.UTIL.Gray[500]}`,
                    },
                },
            },
        },
    };
});

export const variants = { minimalist, base };

export const tableTheme = defineMultiStyleConfig({
    variants,
});
