import {
    OnboardingState,
    StepComponentProps,
} from 'onboarding/onboarding.model';
import { ReactElement, useContext, useEffect, useState } from 'react';

import FileUpload from 'components/FileUploader';
import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import PX from 'ui/px';
import { FlexCol } from 'ui/loulaFlex';
import FONTS from 'ui/fonts';
import { Radio } from 'ui/components/Radio';
import { PrimaryButton } from 'ui/components/Button';
import { ResumeItemCard } from '../ResumeItemCard';
import { FileUpload as FileUploadType, ResumeItem } from 'models/schemas';
import { ResumeForm } from '../ResumeForm';
import { v4 as uuidV4 } from 'uuid';
import { OnboardingContext } from 'onboarding/OnboardingContext';
import { AlertDialog } from 'ui/AlertDialogue';
import { useRadioGroup } from 'ui/components/hooks/useRadioGroup';

export const WorkHistoryStep = ({
    commonStyling,
}: StepComponentProps): ReactElement => {
    const onboardingContext = useContext(OnboardingContext);

    const { formInstance, formData, saveField } = onboardingContext ?? {};

    const { formState, setValue, watch } = formInstance ?? {};
    const { errors } = formState ?? {};

    const resumeItems: ResumeItem[] = watch?.(
        'resumeItems',
        formData?.resumeItems ?? [],
    );

    const [activeResumeItem, setActiveResumeItem] = useState<ResumeItem | null>(
        null,
    );
    const [isActiveItemNew, setIsActiveItemNew] = useState(false);

    const {
        getRootProps,
        getRadioProps,
        pathwayChoice,
        showAlertDialog,
        setShowAlertDialog,
    } = useWorkHistoryPathway(onboardingContext);

    const openNewResumeItem = () => {
        setActiveResumeItem({
            id: uuidV4(),
            title: '',
            description: '',
            startDate: '',
            endDate: '',
            current: false,
            location: '',
            company: '',
        });
        setIsActiveItemNew(true);
    };

    const onSave = (resumeItem: ResumeItem) => {
        //console.log('should add or update this resume item: ', resumeItem);

        const existingItem = resumeItems?.find(
            (item) => item.id === resumeItem.id,
        );

        if (!!existingItem) {
            const newResumeItems = resumeItems?.map((item) =>
                item.id === resumeItem.id ? resumeItem : item,
            );
            setValue?.('resumeItems', newResumeItems, { shouldDirty: true });
        } else {
            setValue?.('resumeItems', [...(resumeItems ?? []), resumeItem], {
                shouldDirty: true,
            });
        }

        setActiveResumeItem(null);
    };

    const onDelete = (resumeItemId: string) => {
        setValue?.(
            'resumeItems',
            resumeItems?.filter((item) => item.id !== resumeItemId),
            { shouldDirty: true },
        );
    };

    const onDiscard = () => {
        setIsActiveItemNew(false);
        setActiveResumeItem(null);
    };

    return (
        <Flex {...commonStyling} aria-label="work history form">
            <FlexCol gap={PX.SPACING.PX.L} className="internalWrapper">
                <FlexCol gap={PX.SPACING.PX.L}>
                    <FONTS.H2>
                        Let’s learn more about your years of experience working
                        as a doula.
                    </FONTS.H2>
                    <FONTS.P1>
                        We need to collect details of your work history as a
                        doula covering the most recent 5 years.
                    </FONTS.P1>
                    <FONTS.P1>
                        If you have gaps in your work history of more than 6
                        months, indicate the gap and describe what you were
                        doing during that time.
                    </FONTS.P1>
                    <FONTS.P1>
                        If you do not yet have five years of experience as a
                        doula, include as many experiences as relevant.
                    </FONTS.P1>
                </FlexCol>

                <FlexCol {...getRootProps()} gap={PX.SPACING.PX.S}>
                    <Radio
                        {...getRadioProps({ value: 'upload' })}
                        value="upload"
                    >
                        <FONTS.P1>
                            I have a resume or CV that meets the requirements
                            above (recommended)
                        </FONTS.P1>
                    </Radio>
                    <Radio
                        {...getRadioProps({ value: 'manual' })}
                        value="manual"
                    >
                        <FONTS.P1>
                            I want to fill in my work history manually
                        </FONTS.P1>
                    </Radio>
                </FlexCol>

                {pathwayChoice === 'manual' && (
                    <FlexCol gap={PX.SPACING.PX.M}>
                        <FONTS.H2>Your Experience</FONTS.H2>

                        {resumeItems?.map((item) => (
                            <ResumeItemCard
                                key={`resume_item_${item.id}`}
                                item={item}
                                onClickEdit={() => setActiveResumeItem(item)}
                                onClickDelete={() => onDelete(item.id)}
                            />
                        ))}

                        {!!activeResumeItem && (
                            <ResumeForm
                                resumeItem={activeResumeItem}
                                onSave={onSave}
                                onDiscard={onDiscard}
                                isEditting={!isActiveItemNew}
                            />
                        )}

                        <PrimaryButton
                            disabled={!!activeResumeItem}
                            onClick={() => {
                                if (!activeResumeItem) openNewResumeItem();
                            }}
                        >
                            Add Work Experience
                        </PrimaryButton>
                    </FlexCol>
                )}
                {pathwayChoice === 'upload' && (
                    <FlexCol gap={PX.SPACING.REM.M}>
                        <FlexCol gap={PX.SPACING.PX.S}>
                            <FONTS.H2>Upload your resume or CV</FONTS.H2>
                            <FONTS.P2>
                                Make sure the resume/CV covers a span of five
                                years or more. If you have less than five years
                                of doula experience, make sure the resume covers
                                all the experiences you have had.
                            </FONTS.P2>
                        </FlexCol>
                        <FormControl
                            id="resumeFile"
                            isRequired
                            isInvalid={!!errors?.resumeFile}
                        >
                            <FileUpload
                                fieldName="resumeFile"
                                fieldValue={formData?.['resumeFile'] ?? []}
                                maxFiles={1}
                                formInstance={formInstance}
                                saveField={saveField}
                            />
                            <FormErrorMessage>
                                This is required
                            </FormErrorMessage>
                        </FormControl>
                    </FlexCol>
                )}
            </FlexCol>

            {showAlertDialog && (
                <AlertDialog
                    title={showAlertDialog?.title ?? ''}
                    leastDestructiveBtnText="Cancel"
                    destructiveBtnText={showAlertDialog?.destructiveLabel ?? ''}
                    onClickDestructive={
                        showAlertDialog?.onClickDestructive ?? (() => {})
                    }
                    destructiveIcon="Delete"
                    isDestructiveEnabled={true}
                    isDestructiveDangerous={true}
                    onClickLeastDestructive={() => {
                        setShowAlertDialog(null);
                    }}
                />
            )}
        </Flex>
    );
};

const useWorkHistoryPathway = (
    onboardingContext: OnboardingState | undefined,
) => {
    const { formInstance, formData } = onboardingContext ?? {};

    const { setValue, watch } = formInstance ?? {};

    const resumeItems: ResumeItem[] = watch?.(
        'resumeItems',
        formData?.resumeItems ?? [],
    );

    const resumeFile: FileUploadType[] | undefined = watch?.(
        'resumeFile',
        formData?.resumeFile,
    );

    const [pathwayChoice, setPathwayChoice] = useState<string>(
        (formData?.resumeItems?.length ?? 0 > 0)
            ? 'manual'
            : (formData?.resumeFile?.length ?? 0 > 0)
              ? 'upload'
              : '',
    );

    const [showAlertDialog, setShowAlertDialog] = useState<{
        title: string;
        destructiveLabel: string;
        onClickDestructive: () => void;
    } | null>(null);

    const onChangePathway = (nextVal: string) => {
        if (pathwayChoice === 'manual' && resumeItems?.length > 0) {
            setRadioValue(pathwayChoice);
            setShowAlertDialog({
                title: 'By selecting this option, the Work Experiences you have created will be discarded.',
                destructiveLabel: 'Discard changes',
                onClickDestructive: () => {
                    setValue?.('resumeItems', [], { shouldDirty: true });
                    setRadioValue(nextVal);
                    setPathwayChoice(nextVal);
                    setShowAlertDialog(null);
                },
            });
        } else if (
            pathwayChoice === 'upload' &&
            (resumeFile?.length ?? 0) > 0
        ) {
            setRadioValue(pathwayChoice);
            setShowAlertDialog({
                title: 'By selecting this option, your uploaded resume will be discarded.',
                destructiveLabel: 'Discard file',
                onClickDestructive: () => {
                    setValue?.('resumeFile', [], { shouldDirty: true });
                    setRadioValue(nextVal);
                    setPathwayChoice(nextVal);
                    setShowAlertDialog(null);
                },
            });
        } else {
            setPathwayChoice(nextVal);
        }
    };

    const {
        getRadioProps,
        getRootProps,
        setValue: setRadioValue,
    } = useRadioGroup({
        defaultValue: pathwayChoice,
        onChangeString: onChangePathway,
    });

    const [hasAccountedForLoadedToggling, setHasAccountedForLoadedToggling] =
        useState<boolean>(false);

    useEffect(
        function accountForLoadedToggling() {
            if (!formData || hasAccountedForLoadedToggling) return;

            setHasAccountedForLoadedToggling(true);

            let loadedPathway = null;

            if (
                (formData.resumeFile?.length ?? 0) > 0 &&
                pathwayChoice !== 'upload'
            ) {
                loadedPathway = 'upload';
            } else if (
                (formData.resumeItems?.length ?? 0) > 0 &&
                pathwayChoice !== 'upload'
            ) {
                loadedPathway = 'manual';
            }

            if (!!loadedPathway && pathwayChoice !== loadedPathway) {
                setPathwayChoice(loadedPathway);
                setRadioValue(loadedPathway);
            }
        },
        [formData, hasAccountedForLoadedToggling, pathwayChoice, setRadioValue],
    );

    return {
        getRadioProps,
        getRootProps,
        pathwayChoice,
        setPathwayChoice,
        showAlertDialog,
        setShowAlertDialog,
    };
};
