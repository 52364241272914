import { useLocation } from 'react-router-dom';
import COLORS from 'ui/colors';
import { Avatar } from 'ui/components/Avatar';
import { IconButton } from 'ui/components/IconButton';
import { Logo } from 'ui/components/logo';
import { NavBarButton } from 'ui/components/NavBarButton';
import FONTS from 'ui/fonts';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import { PXSTR } from 'ui/px';
import { Zyg } from 'util/Utils';
import { HEADER_MIN_HEIGHT } from './PageHeader';
import useScreenSize from 'hooks/useScreenSize';

export const NavBar = ({
    isSheetVisible,
    setIsSheetVisible,
}: {
    isSheetVisible: boolean;
    setIsSheetVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const { isNarrowScreen } = useScreenSize();
    return (
        <FlexCol
            paddingY={Zyg(PXSTR.SM, PXSTR.M)}
            paddingX={PXSTR.S}
            justifyContent="space-between"
            role="navigation"
            position={Zyg('absolute', 'relative')}
            width={Zyg('100%', '20%')}
            maxW={Zyg(null, '180px')}
            height={Zyg(`calc(100% - ${HEADER_MIN_HEIGHT}px)`, '100%')}
        >
            <FlexCol gap={PXSTR.XL}>
                <FlexRow
                    className="logoContainer"
                    paddingX={Zyg(PXSTR.XS, PXSTR.S)}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Logo
                        size={45}
                        subMark
                        logoType="nourished"
                        fillColor={COLORS.BRAND.BloodOrange.BRAND}
                    />
                    <FONTS.H2
                        marginRight="auto"
                        marginLeft={PXSTR.S}
                        display={Zyg('block', 'none')}
                        color={COLORS.BRAND.BloodOrange.BRAND}
                    >
                        Loula
                    </FONTS.H2>
                    <IconButton
                        boxSize={6}
                        color={COLORS.PRIMARY.Grey}
                        display={Zyg('block', 'none')}
                        icon={isSheetVisible ? 'Menu' : 'Close'}
                        onClick={() => setIsSheetVisible((prevVal) => !prevVal)}
                    />
                </FlexRow>

                <NavMenu />
            </FlexCol>

            <FlexRow
                className="avatarContainer"
                paddingX={Zyg(PXSTR.M, PXSTR.SM)}
                paddingTop={Zyg(PXSTR.S, 0)}
                paddingBottom={Zyg(0, PXSTR.M)}
                justifyContent="space-between"
                alignItems="end"
                borderTop={Zyg(`1px solid ${COLORS.UTIL.Gray[600]}`, 'none')}
            >
                <FONTS.P1 display={Zyg('block', 'none')}>Aminata Sow</FONTS.P1>
                <Avatar initials="AS" size={isNarrowScreen ? 'sm' : 'lg'} />
            </FlexRow>
        </FlexCol>
    );
};

const NavMenu = () => {
    const { pathname } = useLocation();

    return (
        <FlexCol>
            <NavBarButton
                icon="Home"
                label="Home"
                link="/home"
                isActive={pathname === '/home'}
            />
            <NavBarButton
                icon="Users"
                label="Clients"
                link="/clients"
                isActive={pathname === '/clients'}
            />
            <NavBarButton
                icon="Receipt"
                label="Visits"
                link="/visits"
                isActive={pathname === '/visits'}
            />
            <NavBarButton
                icon="Coins"
                label="Payments"
                link="/payments"
                isActive={pathname === '/payments'}
            />
            <NavBarButton
                icon="Cog"
                label="Settings"
                link="/settings"
                isActive={pathname === '/settings'}
            />
        </FlexCol>
    );
};
