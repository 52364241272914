import { useRadioGroup } from 'ui/components/hooks/useRadioGroup';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import PX from 'ui/px';
import FONTS from 'ui/fonts';
import { AutoComplete } from 'ui/components/AutoComplete';
import { TextTag } from 'ui/components/TextTag';
import { Radio } from 'ui/components/Radio';
import { useContext, useMemo } from 'react';
import { LANGUAGES } from 'models/constants';
import { OnboardingContext } from 'onboarding/OnboardingContext';
import COLORS from 'ui/colors';
import { AutoCompletePossibility } from 'ui/ui.models';

export const LanguageProficiencyPicker = ({}) => {
    const onboardingContext = useContext(OnboardingContext);
    const { formData, formInstance, isLoaded } = onboardingContext ?? {};
    const { setValue, watch } = formInstance ?? {};

    const languageState: Record<string, string | null> = watch?.(
        'languages',
        formData?.languages ?? {},
    );

    const onClickLangInAutoComplete = (languageId: string) => {
        setValue?.(
            'languages',
            { ...languageState, [languageId]: null },
            { shouldDirty: true },
        );
    };

    const onRemoveLanguage = (languageId: string) => () => {
        const newState = { ...languageState };
        delete newState[languageId];
        setValue?.('languages', newState, { shouldDirty: true });
    };

    const onChangeLanguageProficiency =
        (languageId: string) => (newProficiency: string) => {
            setValue?.(
                'languages',
                { ...languageState, [languageId]: newProficiency },
                { shouldDirty: true },
            );
        };

    const languageIds = Object.keys(languageState);

    const languagesPossibilities: AutoCompletePossibility[] = useMemo(() => {
        return LANGUAGES.map((language) => {
            return {
                label: language,
                value: language,
                disabled: language in languageState,
            };
        });
    }, [languageState]);

    return (
        <FlexCol gap={PX.SPACING.PX.L}>
            <AutoComplete
                id="language"
                searchIconOnRight={true}
                label="Besides English, what language(s) do you deliver doula care in? (optional)"
                subLabel="Select as many as applicable. "
                possibilities={languagesPossibilities}
                onClickItem={onClickLangInAutoComplete}
                noResultsHeader="Languages"
                permitManualEntry={true}
            />
            {isLoaded && (
                <>
                    <FlexRow gap={PX.SPACING.REM.M} wrap="wrap">
                        {languageIds.map((languageId, idx) => (
                            <TextTag
                                key={`language_tag_${idx}`}
                                text={languageId}
                                onClickClose={onRemoveLanguage(languageId)}
                            />
                        ))}
                    </FlexRow>
                    {Object.entries(languageState).map(
                        ([languageId, proficiency], idx) => (
                            <ProficiencySpecification
                                key={`proficiency_spec_${idx}`}
                                languageId={languageId}
                                defaultValue={proficiency ?? undefined}
                                onChange={onChangeLanguageProficiency(
                                    languageId,
                                )}
                                showError={!proficiency}
                            />
                        ),
                    )}
                </>
            )}
        </FlexCol>
    );
};

const ProficiencySpecification = ({
    languageId,
    defaultValue,
    onChange,
    showError,
}: {
    languageId?: string;
    defaultValue?: string;
    onChange: (_val: string) => void;
    showError?: boolean;
}) => {
    const { getRootProps, getRadioProps } = useRadioGroup({
        defaultValue,
        onChangeString: onChange,
    });

    return (
        <FlexCol {...getRootProps()} gap={PX.SPACING.PX.SM}>
            <FONTS.P1>
                What is your proficiency level in <b>{languageId}</b>?
            </FONTS.P1>

            <Radio
                {...getRadioProps({ value: 'proficient' })}
                value="proficient"
                label="Proficient"
            />

            <Radio
                {...getRadioProps({ value: 'fluent' })}
                value="fluent"
                label="Fluent"
            />

            <Radio
                {...getRadioProps({ value: 'native' })}
                value="native"
                label="Bilingual/Native Speaker"
            />

            {showError && (
                <FONTS.P2 color={COLORS.PRIMARY.Error}>
                    Proficiency level required
                </FONTS.P2>
            )}
        </FlexCol>
    );
};
