import { useRadioGroup as baseUseRadio } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

export const useRadioGroup = ({
    defaultValue,
    onChangeString,
    onChangeBool,
    loadingDependency,
}: {
    defaultValue?: string | boolean;
    onChangeString?: (_val: string) => void;
    onChangeBool?: (_val: boolean) => void;
    loadingDependency?: unknown;
}) => {
    const { getRadioProps, getRootProps, setValue } = baseUseRadio({
        defaultValue: defaultValue?.toString(),
        onChange: (newVal: string) => {
            if (onChangeBool) {
                onChangeBool(newVal === 'true');
            } else if (onChangeString) {
                onChangeString(newVal);
            }
        },
    });

    const wrappedGetRadioProps = ({ value }: { value: string | boolean }) =>
        getRadioProps({ value: value.toString() });

    const [hasAccountedForLoadedToggling, setHasAccountedForLoadedToggling] =
        useState<boolean>(false);

    useEffect(
        function accountForLoadedToggling() {
            if (
                !loadingDependency ||
                hasAccountedForLoadedToggling ||
                defaultValue === undefined
            )
                return;
            setHasAccountedForLoadedToggling(true);
            setValue(defaultValue.toString());
        },
        [
            loadingDependency,
            hasAccountedForLoadedToggling,
            setValue,
            defaultValue,
        ],
    );

    return {
        getRadioProps: wrappedGetRadioProps,
        getRootProps,
        setValue,
    };
};
