import { Box, BoxProps } from '@chakra-ui/react';
import { Waves } from 'ui/animations/gradients';
import COLORS from 'ui/colors';
import PX from 'ui/px';
import { Zyg } from 'util/Utils';

//For filling spaces while optimistaclly loading, a basic animated gradient moving horizontally
export const PlaceholderLoadingBar = (props: BoxProps) => {
    return (
        <Box
            className="placeholderLoadingBar"
            flex={1}
            width="100%"
            height={Zyg(PX.SPACING.REM.M, PX.SPACING.REM.SM)}
            borderRadius={PX.RADII.SM}
            {...Waves({ sp: COLORS.UTIL.Gray })}
            {...props}
        />
    );
};
