import { Flex, FlexProps } from '@chakra-ui/react';
import COLORS from 'ui/colors';
import FONTS from 'ui/fonts';
import PX from 'ui/px';
import React from 'react';
import { show } from '@intercom/messenger-js-sdk';
import { LINK } from 'ui/components/Link';

export const HelpTag = ({ ...props }: FlexProps): React.ReactElement => {
    return (
        <Flex
            aria-label="Help Tag"
            role="region"
            width="100%"
            align="center"
            gap={PX.SPACING.PX.XS}
            paddingY={PX.SPACING.REM.M}
            {...props}
        >
            <FONTS.P2
                marginLeft={PX.SPACING.REM.M}
                color={COLORS.UTIL.Gray[900]}
            >
                Need help?
            </FONTS.P2>
            <LINK.Small onClick={show}>Chat with us</LINK.Small>
        </Flex>
    );
};
