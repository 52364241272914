import FONTS from 'ui/fonts';
import { FlexCol } from 'ui/loulaFlex';
import DatePicker, { DatePickerProps } from 'react-datepicker';
import { UseFormRegisterReturn } from 'react-hook-form';
import { CalendarIcon } from '@chakra-ui/icons';
import { forwardRef } from 'react';
import PX from 'ui/px';
import { TextInputStyling } from './TextInput';
import { useEffect } from 'react';

type DateInputProps = UseFormRegisterReturn & {
    label?: string;
    valueAsDate: Date;
    dateFormat?: string;
    setValueAsString?: (_value: string) => void;
    setValueAsDate?: (_value: Date | null) => void;
    datePickerProps?: DatePickerProps;
};

export const DateInput = forwardRef<DatePicker, DateInputProps>(
    (
        {
            label,
            valueAsDate,
            setValueAsString,
            dateFormat,
            setValueAsDate,
            datePickerProps,
            ...otherProps
        },
        ref,
    ) => {
        const setValues = (date: Date | null) => {
            if (!date) return;
            if (setValueAsDate) {
                setValueAsDate(date);
            } else if (setValueAsString) {
                setValueAsString(date?.toISOString() ?? '');
            }
        };

        useEffect(() => {
            setValues(valueAsDate);
        }, []);

        return (
            <FlexCol
                sx={{
                    '.date-picker': TextInputStyling,
                    '.date-picker-icon': {
                        right: PX.SPACING.PX.SM,
                        top: PX.SPACING.PX.SM,
                        padding: 0,
                        cursor: 'pointer',
                    },

                    '.react-datepicker__input-container': {
                        width: 'fit-content',
                        alignItems: 'center',
                    },
                }}
                width="fit-content"
                gap={PX.SPACING.PX.S}
            >
                <FONTS.P1 fontWeight={500}>{label}</FONTS.P1>
                <DatePicker
                    className="date-picker"
                    {...otherProps}
                    ref={ref}
                    showIcon
                    toggleCalendarOnIconClick
                    calendarIconClassName="date-picker-icon"
                    dateFormat={dateFormat ?? 'MM/dd/yyyy'}
                    icon={<CalendarIcon boxSize={5} />}
                    selected={valueAsDate}
                    onChange={setValues}
                    showMonthYearPicker={
                        !datePickerProps
                            ? true
                            : datePickerProps.showMonthYearPicker
                    }
                    required={true}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    {...((datePickerProps as any) ?? {})}
                />
            </FlexCol>
        );
    },
);
