import FONTS from 'ui/fonts';
import { FlexRow } from '../loulaFlex';
import PX from 'ui/px';
import COLORS from 'ui/colors';
import { FlexProps } from '@chakra-ui/react';
import { IconButton } from 'ui/components/IconButton';

export enum TextTagColorSchemes {
    DEFAULT = 'default',
    GREY_LIGHT = 'grey_light',
    GREY_HEAVY = 'grey_heavy',
    GREEN_LIGHT = 'green_light',
    GREEN_HEAVY = 'green_heavy',
    ORANGE_LIGHT = 'orange_light',
    ORANGE_HEAVY = 'orange_heavy',
    BLUE_LIGHT = 'blue_light',
    BLUE_HEAVY = 'blue_heavy',
}

const ColorSchemeFields = {
    [TextTagColorSchemes.DEFAULT]: {
        borderColor: COLORS.PRIMARY.Grey,
        background: COLORS.UTIL.Gray.WARM,
        color: COLORS.UTIL.Gray[900],
    },
    [TextTagColorSchemes.GREEN_HEAVY]: {
        borderColor: COLORS.UTIL.Green[700],
        background: COLORS.UTIL.Green[700],
        color: COLORS.UTIL.Gray.WARM,
    },
    [TextTagColorSchemes.BLUE_HEAVY]: {
        borderColor: COLORS.BRAND.Periwinkle[800],
        background: COLORS.BRAND.Periwinkle[800],
        color: COLORS.UTIL.Gray.WARM,
    },
    [TextTagColorSchemes.GREY_HEAVY]: {
        borderColor: COLORS.UTIL.Gray[800],
        background: COLORS.UTIL.Gray[800],
        color: COLORS.UTIL.Gray.WARM,
    },
    [TextTagColorSchemes.ORANGE_HEAVY]: {
        borderColor: COLORS.UTIL.WarningOrange[700],
        background: COLORS.UTIL.WarningOrange[700],
        color: COLORS.UTIL.Gray.WARM,
    },
    [TextTagColorSchemes.GREEN_LIGHT]: {
        borderColor: COLORS.UTIL.Green[500],
        background: COLORS.UTIL.Green[200],
        color: COLORS.UTIL.Green[800],
    },
    [TextTagColorSchemes.ORANGE_LIGHT]: {
        borderColor: COLORS.UTIL.WarningOrange[500],
        background: COLORS.UTIL.WarningOrange[200],
        color: COLORS.UTIL.WarningOrange[800],
    },
    [TextTagColorSchemes.BLUE_LIGHT]: {
        borderColor: COLORS.BRAND.Periwinkle[500],
        background: COLORS.BRAND.Periwinkle[200],
        color: COLORS.BRAND.Periwinkle[800],
    },
    [TextTagColorSchemes.GREY_LIGHT]: {
        borderColor: COLORS.UTIL.Gray[500],
        background: COLORS.UTIL.Gray[200],
        color: COLORS.UTIL.Gray[800],
    },
};

export const TextTag = ({
    text,
    onClickClose,
    colorScheme = TextTagColorSchemes.DEFAULT,
    ...props
}: FlexProps & {
    text: string | undefined;
    onClickClose?: () => void;
    colorScheme?: TextTagColorSchemes;
}) => {
    return (
        <FlexRow
            align="center"
            justify="center"
            gap={PX.SPACING.PX.S}
            borderRadius={PX.RADII.SM}
            padding={PX.SPACING.PX.XS}
            border={`1px solid ${COLORS.STROKES.HEAVY}`}
            {...ColorSchemeFields[colorScheme]}
            {...props}
        >
            <FONTS.TAG color={ColorSchemeFields[colorScheme].color}>
                {text}
            </FONTS.TAG>

            {onClickClose && (
                <IconButton
                    icon="Close"
                    color={ColorSchemeFields[colorScheme].color}
                    onClick={onClickClose}
                    size={16}
                />
            )}
        </FlexRow>
    );
};
