import { DoulaPortalLayout } from 'doulaPortal/DoulaPortalLayout';
import { PrimaryButton, SecondaryButton } from 'ui/components/Button';
import FONTS from 'ui/fonts';

const Payments = () => {
    return (
        <DoulaPortalLayout
            pageHeader="Payments Header"
            pageActionButtons={[
                <PrimaryButton key="act1_btn">Act 1</PrimaryButton>,
                <SecondaryButton key="act2_btn">Act 2</SecondaryButton>,
            ]}
        >
            <FONTS.CAPTION textAlign="center">
                Good ol' Payments Content
            </FONTS.CAPTION>
        </DoulaPortalLayout>
    );
};

export default Payments;
