import { FlexProps } from '@chakra-ui/react';
import COLORS from 'ui/colors';
import FONTS from 'ui/fonts';
import { ICONS } from 'ui/icons';
import { FlexRow } from 'ui/loulaFlex';
import PX from 'ui/px';

export const Alert = ({
    content,
    contentComponents,
}: FlexProps & {
    content?: string;
    contentComponents?: React.ReactElement;
}) => {
    return (
        <FlexRow
            width="100%"
            alignItems="center"
            padding={PX.SPACING.REM.M}
            gap={PX.SPACING.REM.M}
            background="rgba(255, 138, 0, 0.20)"
            borderRadius={PX.RADII.MD}
        >
            <ICONS.WarningCircle size={16} color={COLORS.PRIMARY.Warning} />
            {contentComponents ? (
                contentComponents
            ) : (
                <FONTS.P1>{content}</FONTS.P1>
            )}
        </FlexRow>
    );
};
