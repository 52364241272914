import { BoxProps, chakra } from '@chakra-ui/react';
import { useState } from 'react';
import COLORS from 'ui/colors';
import { ICONS } from 'ui/icons';
import PX from 'ui/px';

export const IconButton = ({
    icon,
    color = COLORS.UTIL.Gray[900],
    hoverColor,
    activeColor,
    size = 24,
    ...props
}: BoxProps & {
    size?: number;
    icon: keyof typeof ICONS;
    color?: string;
    hoverColor?: string;
    activeColor?: string;
}) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const [isActive, setIsActive] = useState<boolean>(false);

    const IconComponent = ICONS[icon];
    return (
        <chakra.button
            display="flex"
            _hover={{
                background: `rgba(${COLORS.BRAND.Periwinkle[800]}, 1)`,
            }}
            role="button"
            tabIndex={0}
            borderRadius={PX.RADII.ROUNDED}
            transition="all 0.3s"
            cursor="pointer"
            css={{ WebkitTapHighlightColor: 'transparent' }}
            justifyContent="center"
            alignItems="center"
            {...props}
        >
            <IconComponent
                size={size}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => {
                    setIsHovered(false);
                    setIsActive(false);
                }}
                onMouseDown={() => setIsActive(true)}
                onMouseUp={() => setIsActive(false)}
                color={color}
                style={{
                    transition: 'all 0.3s',
                    background: 'transparent',
                    ...(isActive
                        ? {
                              color: activeColor ?? color,
                              transform: 'scale(0.9)',
                          }
                        : isHovered
                          ? { color: hoverColor ?? color }
                          : {
                                color: color,
                            }),
                }}
            />
        </chakra.button>
    );
};
