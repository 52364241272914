import { Box, BoxProps } from '@chakra-ui/react';
import COLORS from 'ui/colors';
import PX from 'ui/px';
import SHADOWS from 'ui/shadows';

export const Sheet = ({ children, ...props }: BoxProps) => {
    return (
        <Box
            width="100%"
            height="fit-content"
            background={COLORS.UTIL.Gray.SHEET}
            borderRadius={PX.RADII.LG}
            boxShadow={SHADOWS.elevationOne}
            border={`1px solid ${COLORS.UTIL.Gray[500]}`}
            {...props}
        >
            {children}
        </Box>
    );
};

export const SheetTab = ({ children, ...props }: BoxProps) => {
    return (
        <Box
            width="100%"
            height="fit-content"
            background={COLORS.UTIL.Gray.WARM}
            borderRadius={PX.RADII.LG}
            border={`1px solid ${COLORS.UTIL.Gray[500]}`}
            {...props}
        >
            {children}
        </Box>
    );
};
